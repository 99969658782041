h1,
h2 {
    z-index: 2;
}

.underline-input {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid white;
    display: block;
    width: 100%;
    color: $light;
}

.underline-input-dark {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid $dark;
    display: block;
    width: 100%;
    color: $light;

    &::placeholder {
        color: #C1C0C0
    }
}

.underline-input-danger {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid $danger;
    display: block;
    width: 100%;
    color: $light;

    &::placeholder {
        color: #C1C0C0
    }
}

.underline-input-modal-dark {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 2px solid $dark;
    display: block;
    width: 100%;
    color: $black;
}

.card-video {
    width: 100%;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // object-position: 0% 100%;
        transform: scale(1.015);

        @include media-breakpoint-up(sm) {
            height: 26.5vh;
        }

        @include media-breakpoint-down(sm) {
            height: 23.5vh;
        }
    }
}

.home-title-icon {
    @media only screen and (max-width: 677px) {
        width: 2.7rem;
        height: 2.7rem;
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(16px, -6px);
        z-index: -1;
    }

    @media only screen and (min-width: 678px) {
        width: 3.15rem;
        height: 3.15rem;
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(5px, -3px);
        z-index: -1;
    }

}

.home-header-title-icon {
    @media only screen and (max-width: 677px) {
        width: 2.25rem;
        height: 2.25rem;
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(-13px, -16px);
        z-index: 0;
    }

    @media only screen and (min-width: 678px) {
        width: 2.65rem;
        height: 2.65rem;
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(-16px, -15px);
        z-index: 0;
    }

}

@media only screen and (max-width: 677px) {
    .responsive-center-item {
        justify-content: center !important;
        padding: 0 !important;
        padding-bottom: map-get($map: $spacers, $key: 3) !important;

        .responsive-icon {
            margin: 0 !important;
        }
    }
}

#poc-a-poc {
    transform: rotateZ(-1deg);
}

.show-left {
    @include media-breakpoint-down(sm) {
        transition: .35s;
        padding-left: 1.5rem !important;
    }
}

.show-right {
    @include media-breakpoint-down(sm) {
        transition: .35s;
        padding-right: 1.5rem !important;
    }
}

.show-all {
    transition: .35s;
}

.promotion-modal {

    .modal-content {
        border-radius: 3px !important;
    }

    .close {
        top: 10px;
        right: 15px;

        svg {
            transition: .225s;
        }

        &:hover svg {
            color: $coal;
            transition: .225s;
        }
    }

    img,
    video {
        height: auto;

        @include media-breakpoint-up(md) {
            max-width: 100%;
            max-height: 32vh;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
            max-height: 30vh;
        }
    }

    .tags {
        @include media-breakpoint-up(md) {
            font-size: $h4-font-size;
        }

        @include media-breakpoint-down(md) {
            font-size: $h5-font-size;
        }
    }

    .title {
        @include media-breakpoint-up(md) {
            font-size: $h3-font-size;
        }

        @include media-breakpoint-down(md) {
            font-size: $h4-font-size;
        }
    }

    .content {
        @include media-breakpoint-up(md) {
            font-size: $h4-font-size * .8;
        }

        @include media-breakpoint-down(md) {
            font-size: $h5-font-size *.85;
        }
    }

    a:hover {
        color: $coal;
    }
}

.title {
    .text-1 {
        @include media-breakpoint-down(md) {
            font-size: $h5-font-size;
        }

        @include media-breakpoint-up(md) {
            font-size: $h4-font-size;
        }
    }
}

.bottom-card {
    max-height: 100px !important;
    height: auto !important;
}

.mosaic {
    .mosaic-grid {
        @include media-breakpoint-up(lg) {
            display: grid !important;
            grid-auto-rows: 190px;
            grid-template-columns: repeat(14, 1fr); // 14 columns
            gap: 0.75rem;
            color: white;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .overlay {
                background-color: rgba(0, 0, 0, 0.3);
                width: 100%;
                height: 100%;
                z-index: 10;
                transition: .225s;
            }
        }

        .grid-1 {
            grid-column: 1 / 7;
        }

        .grid-2 {
            grid-column: 7 / 11;
        }

        .grid-3 {
            grid-column: 11 / 15;
        }

        .grid-4 {
            grid-column: 1 / 6;
        }

        .grid-5 {
            grid-column: 6 / 11;
        }

        .grid-6 {
            grid-column: 11 / 15;
        }

        .grid-half-1 {
            grid-column: 1 / 8;
        }

        .grid-half-2 {
            grid-column: 8 / 15;
        }

        .grid-single {
            grid-column: 1 / 15;
        }

        .mosaic-item {
            transition: .225s;

            &:hover {
                transform: scale(1.03);
                transition: .225s;

                .overlay {
                    background-color: rgba(0, 0, 0, 0.6);
                    transition: .225s;
                }
            }
        }
    }

    .mosaic-columns {
        color: white;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .overlay {
            background-color: rgba(0, 0, 0, 0.3);
            width: 100%;
            height: 100%;
            z-index: 10;
            transition: .225s;

            .subtitle {
                font-size: $small-font-size * .935;
            }
        }

        .grid-1 {
            height: 9rem;
        }

        .grid-2 {
            height: 11rem;
        }

        .grid-3 {
            height: 14rem;
        }

        .grid-4 {
            height: 14rem;
        }

        .grid-5 {
            height: 13rem;
        }

        .grid-6 {
            height: 11rem;
        }

        .mosaic-item:last-child {
            flex-grow: 1;
        }
    }
}

.content-swiper {
    .content-card {
        .photo-sm {
            height: 265px !important;
        }
    }

    .swiper-button-prev {
        background-color: rgba(0, 0, 0, 0.6) !important;
        height: 105%;
        width: 36px;
        top: 50% !important;
        left: 0% !important;
        transform: translateY(-44.1%) !important;
        color: white !important;
        font-size: 30px !important;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 30px !important;
    }

    .swiper-button-next {
        background-color: rgba(0, 0, 0, 0.6) !important;
        height: 105%;
        width: 36px;
        top: 50% !important;
        right: 0% !important;
        transform: translateY(-44.1%) !important;
        color: white !important;
        font-size: 30px !important;

        ::after {
            font-size: 30px !important;
        }
    }

    .swiper-button-disabled {
        display: none;
    }
}

.home-banner {
    position: relative;

    @include media-breakpoint-up(lg) {
        height: 16rem;
    }

    @include media-breakpoint-down(lg) {
        height: 7rem;
    }

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.2);

        @include media-breakpoint-up(lg) {
            .content {
                padding: 1.5rem 10rem !important;

                .banner-button {
                    border: none !important;
                    min-width: 18rem;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            .content {
                padding: .85rem 1rem !important;

                p {
                    font-size: $small-font-size * 0.95 !important;
                }

                .banner-button {
                    border: none !important;
                    font-size: $small-font-size * 0.8 !important;
                }
            }
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}

.home-newsletter {
    @include media-breakpoint-up(lg) {
        background: url("../../../public/img/newsletter-banner.png");
    }

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        z-index: 1;
    }

    .overlay {
        position: relative;
        width: 100%;
        background-color: rgba(133, 133, 133, 0.2);
        z-index: 2;

        .form {
            width: 425px;
        }
    }

    .hs-button {
        background-color: $coal !important;
        border: 1px solid $coal !important;
        color: $white !important;
        box-shadow: none !important;
        border-radius: $tzo-radius !important;
        // padding: .35rem 3rem !important;
        width: 100% !important;
    }
}

.sections-slider {
    width: auto !important;

    .swiper-wrapper {
        .swiper-slide:first-child {
            padding-left: 24px !important;
        }

        .swiper-slide:last-child {
            padding-right: 24px !important;
        }
    }
}

.swiper-slide {
    height: auto;
}

.home-title {
    @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
    }

    @include media-breakpoint-down(lg) {
        font-size: calc(1.275rem + 0.3vw);
    }
}

.home-filter-section {
    position: relative;
    z-index: 100;

    section {
        border-color: rgba(255, 255, 255, 0.5) !important;
    }
}

.home-more {
    @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
    }

    @include media-breakpoint-down(lg) {
        font-size: 14px;
    }
}

.featured-card {
    min-height: 394px;

    .picture {
        height: 100%;

        img {
            // max-height: 400px;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .stamp {
        width: 48px;
        height: 48px;
    }

    .description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
    }

    @include media-breakpoint-up(lg) {
        .placeholder-lg {
            min-height: 1.75em;
        }
    }
}

.subscribe-banner {
    .title {
        @include media-breakpoint-up(lg) {
            font-size: $h4-font-size;
        }

        @include media-breakpoint-down(lg) {
            font-size: $font-size-base;
        }
    }

    .subtitle {
        @include media-breakpoint-up(lg) {
            font-size: $h6-font-size;
        }

        @include media-breakpoint-down(lg) {
            font-size: $small-font-size;
        }
    }

    button {
        @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
        }

        @include media-breakpoint-down(lg) {
            font-size: $small-font-size;
        }
    }
}

.villa-promo-banner {
    height: 100%;

    @include media-breakpoint-up(lg) {
        height: 550px;
    }

    @include media-breakpoint-down(lg) {
        height: 550px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.40);
    }

    .content {
        z-index: 1;

        .title {
            @include media-breakpoint-up(lg) {
                font-size: 45px;
            }
        }

        .subtitle {
            @include media-breakpoint-down(lg) {
                font-size: $font-size-base;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 37%;
        }
    }
}

.home-hero {
    @include media-breakpoint-up(lg) {
        height: 90vh;
    }

    @include media-breakpoint-down(lg) {
        .content {
            height: 75vw;
        }
    }

    img {
        @include media-breakpoint-up(lg) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
            height: 85vw;
            object-fit: cover;
        }
    }

    h1 {
        @include media-breakpoint-up(lg) {
            font-size: 64px;
        }

        @include media-breakpoint-down(lg) {
            font-size: 2.35rem;
        }
    }

    .hero-image {
        @include media-breakpoint-up(lg) {
            height: 100%;
        }

        @include media-breakpoint-down(lg) {
            height: 75vw;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            @include media-breakpoint-up(lg) {
                object-position: top;
            }
    
            @include media-breakpoint-down(lg) {
                object-position: center;
            }
        }
    }
}

.featured-container {
    @include media-breakpoint-up(lg) {
        img {
            width: 100%;
            height: 540px;
            object-fit: cover;
        }

        h3 {
            font-size: 3.45rem;
        }
    }

    @include media-breakpoint-down(lg) {
        img {
            width: 100%;
            height: 100vw;
            object-fit: cover;
        }

        h3 {
            font-size: 2.5rem;
        }
    }
}

.home-category-section {
    .h1 {
        @include media-breakpoint-up(lg) {
            font-size: 3.45rem;
        }

        @include media-breakpoint-down(lg) {
            font-size: 2.25rem;
        }
    }

    .category-container,
    h2 {
        padding: 2.25rem 0px;
    }

    .category-container {
        span {
            transition: .225s;
        }
    }

    .category-container:hover {
        span {
            color: #E9CE2C !important;
            transition: .225s;
        }

        .h1 {
            font-family: "fitzgerald-italic";
        }

    }
}

.home-must-container {
    .description {
        @include media-breakpoint-up(lg) {
            width: 75%;
        }
    }
}

.explore-hero {
    @include media-breakpoint-up(lg) {
        height: 80vh;
    }

    .hero-image {
        @include media-breakpoint-up(lg) {
            height: 100%;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    .icon {
        width: 75px;
        height: auto;
    }

}

.newsletter-banner {
    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        z-index: 1;
    }

    .overlay {
        position: relative;
        width: 100%;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            padding: 4.5rem;
        }

        @include media-breakpoint-down(lg) {
            padding: 2.5rem 1.5rem;
        }
    }
}

.banner-section {
    @include media-breakpoint-down(lg) {
        .fs-title {
            font-size: 2rem;
        }
    }
}

.financing-logos {
    img {
        @include media-breakpoint-up(lg) {
            width: auto;
            height: 56px;
        }
    }
}