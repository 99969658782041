// Override Bootstrap default variables here
// Do not edit any of the files in /vendor/bootstrap/scss/!

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fc !default;
$gray-200: #eaecf4 !default;
$gray-300: #dddfeb !default;
$gray-400: #d1d3e2 !default;
$gray-500: #b7b9cc !default;
$gray-600: #858796 !default;
$gray-700: #6e707e !default;
$gray-800: #5a5c69 !default;
$gray-900: #3a3b45 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
$starship: #ECE83A !default;
$saffron : #E9CE2C !default;
$ecru: #F6F4EA !default;
$blue: #4e73df !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e74a3b !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #1cc88a !default;
$teal: #20c9a6 !default;
$cyan: #36b9cc !default;
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary:       $saffron !default;
$secondary:     $ecru !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
// scss-docs-end theme-color-variables


// Custom Colors
$light-primary: mix($primary, $white, 10%) !default;
$brand-google: #ea4335;
$brand-facebook: #3b5998;
$dark-gray: #2e2a2b !default;    
$coal:    #2E2A2B !default;
$sea: #8FCAB9 !default;
$light-coal: #c4c4c4 !default;
$laurel: #B4AE99 !default;
$dark-green: #324439 !default;
$brown: #BD7758 !default;
$beige: #FEFCF3 !default;
$dark-brown: #9B957F !default;

// Set Contrast Threshold
$yiq-contrasted-threshold: 195 !default;

// Typography
$body-color: $black !default;

$font-family-sans-serif: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;

$font-weight-light: 300 !default;
$font-weight-bold: 500 !default;
// $font-weight-base: 400;
$headings-font-weight: 400 !default;

// Shadows
$box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($gray-900, .2) !default;
$box-shadow: 0 0.15rem 1.75rem 0 rgba($gray-900, .15) !default;
// $box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// Borders Radius
$border-radius: 0.35rem !default;
$border-color: darken($gray-200, 2%);
$tzo-radius: 5px !default;

// Buttons
$input-btn-border-width: 2px !default;
$btn-border-radius: 0;
$btn-font-weight: $font-weight-bold;


// Spacing Variables
// Change below variable if the height of the navbar changes
$topbar-base-height: 4.375rem;
// Change below variable to change the width of the sidenav
$sidebar-base-width: 14rem;
// Change below variable to change the width of the sidenav when collapsed
$sidebar-collapsed-width: 7rem;
// Padding and margin variables
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

// Card
$card-cap-bg: $gray-100;
$card-border-color: $border-color;

// Transitions
$transition-collapse: height .15s ease !default;

// Dropdowns
$dropdown-font-size: 0.85rem;
$dropdown-border-color: $border-color;

// Z-index
$zindex-modal-backdrop-modal: 1080 !default;

// Navbar height
$nav-height: 61px;
$mobile-topbar: 55px;
$inline-breadcrumbs-height: 41px;
$mobile-nav-height: 62px;
