.border-gray-500 {
    border-color: $gray-500 !important;
}

.border-coal {
    border-color: $coal !important;
}

.border-transparent-coal {
    border-color: rgba(0, 0, 0, .20) !important;
}

.border-light-coal {
    border-color: $light-coal !important;
}

.border-dark-brown {
    border-color: $dark-brown !important;
}

.text-gray-500 {
    color: $gray-500 !important;
}

.text-gray-600 {
    color: $gray-600 !important;
}

.bg-dark-gray {
    background-color: $dark-gray;
}

.screen-height {
    @include media-breakpoint-up(lg) {
        height: calc(100vh - ($nav-height + $inline-breadcrumbs-height));
    }

    @include media-breakpoint-down(lg) {
        min-height: 100vh;
        min-height: calc(100dvh - $nav-height);
    }
}

.vertical-separator {
    @include media-breakpoint-up(lg) {
        height: 100% !important;
        width: 1px;
    }

    @include media-breakpoint-down(lg) {
        height: auto !important;
        width: 1px;
        background-color: $coal;
        opacity: .13;
    }
}

.sort-ico {
    &.asc {
        transform: rotate(180deg);
    }
}

.new-link-laurel {
    position: relative;
    color: $laurel !important;

    &::before {
        content: '';
        position: absolute;
        bottom: -4px;
        width: 100%;
        height: 2px;
        background: $laurel;
        transition: .225s;
    }

    &:hover {
        &::before {
            width: 0%;
            transition: .225s;
        }
    }
}

.new-link-brown {
    position: relative;
    color: $brown !important;

    &::before {
        content: '';
        position: absolute;
        bottom: -4px;
        width: 100%;
        height: 2px;
        background: $brown;
        transition: .225s;
    }

    &:hover {
        &::before {
            width: 0%;
            transition: .225s;
        }
    }
}

.new-link-coal {
    position: relative;
    color: $coal !important;

    &::before {
        content: '';
        position: absolute;
        bottom: -4px;
        width: 100%;
        height: 2px;
        background: $coal;
        transition: .225s;
    }

    &:hover {
        &::before {
            width: 0%;
            transition: .225s;
        }
    }
}

.new-link-dark-green {
    position: relative;
    color: $dark-green !important;

    &::before {
        content: '';
        position: absolute;
        bottom: -4px;
        width: 100%;
        height: 2px;
        background: $dark-green;
        transition: .225s;
    }

    &:hover {
        &::before {
            width: 0%;
            transition: .225s;
        }
    }
}

.new-link-white {
    position: relative;
    color: $white !important;

    &::before {
        content: '';
        position: absolute;
        bottom: -4px;
        width: 100%;
        height: 2px;
        background: $white;
        transition: .225s;
    }

    &:hover {
        &::before {
            width: 0%;
            transition: .225s;
        }
    }
}


.new-content-card {
    .content-picture {
        object-fit: cover;

        @include media-breakpoint-up(xl) {
            width: 100%;
            height: 330px;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
            height: 75vw;
        }
    }

    .bookmark {
        filter: drop-shadow(0px 0px 0.85px rgba(0, 0, 0, 0.425));

        path {
            fill: $secondary !important;
            transition: .255s;
        }
    }

    .bookmark-active {
        filter: drop-shadow(0px 0px 0.85px rgba(255, 255, 255, .8));
        
        path {
            fill: $dark-green !important;
            transition: .255s;
        }
    }

    .stamp {
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 8px;
            left: 8px;
            object-fit: cover;
            width: 115px;
            height: auto;
        }

        @include media-breakpoint-down(lg) {
            position: absolute;
            top: 8px;
            left: 8px;
            object-fit: cover;
            width: 85px;
            height: auto;
        }
    }

    &:hover {

        .new-link-laurel::before,
        .new-link-brown::before,
        .new-link-coal::before {
            width: 0%;
            transition: 0.225s;
        }
    }

    &.placeholder {
        .picture {
            @include media-breakpoint-up(xl) {
                width: 100%;
                height: 330px;
            }

            @include media-breakpoint-down(lg) {
                width: 100%;
                height: 75vw;
            }
        }
    }
}

.featured-container {
    &:hover {

        .new-link-laurel::before,
        .new-link-brown::before,
        .new-link-coal::before {
            width: 0%;
            transition: 0.225s;
        }
    }
}

.banner-section {
    padding: 7rem 0px;
}

.banner-card {
    @include media-breakpoint-up(lg) {
        height: 625px;
    }

    @include media-breakpoint-down(lg) {
        width: 100%;
        height: 65vh;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .overlay {
        background-color: rgba(50, 68, 57, 0.45);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.laurel-checkbox {
    input[type=checkbox] {
        border-radius: $tzo-radius;
    }

    input:checked[type=checkbox] {
        border: 1px solid $dark-brown;
        background-color: $dark-brown !important;
        background-image: url('../../../public/img/svg/check-icon-filter-white.svg');
    }

    &.lg {
        input {
            width: 25px;
            height: 25px;
        }
    }
}

.invert-color {
    filter: invert(1);
}