footer {
    @include media-breakpoint-up(lg) {
        background-color: $dark-green;
    }

    @include media-breakpoint-down(lg) {
        background-color: $dark-green;
    }

    .logo {
        
        @include media-breakpoint-up(lg) {
            width: 100%;
            max-width: 350px;
        }

        @include media-breakpoint-down(lg) {
            width: 80%;
            max-width: 375px;
        }
    }

    .separator-opacity {
        background-color: #F7F4EA;
        opacity: 20%;

        @include media-breakpoint-up(lg) {
            height: auto;
            width: 1px;
        }

        @include media-breakpoint-down(lg) {
            height: 1px;
            width: 100%;
        }
    }

    .separator-transparent {
        @include media-breakpoint-up(lg) {
            height: auto;
            width: 1px;
        }

        @include media-breakpoint-down(lg) {
            height: 1px;
            width: 100%;
        }
    }

    .separator-opacity-vertical {
        background-color: #F7F4EA;
        opacity: 20%;
        height: auto;
        width: 1px;
    }

    .separator {
        height: auto;
        width: 1px;
        background-color: #F7F4EA;
    }

    .horizontal-separator {
        border-top: 1px solid rgba(247, 244, 234, 0.20);
    }

    .store-button {
        width: auto;

        @include media-breakpoint-up(lg) {
            height: 55px;
        }

        @include media-breakpoint-down(lg) {
            height: 50px;
        }
    }


    .link {
        color: $white;

        &:hover {
            color: #dfdfdf;
        }
    }
}

.footprint {
    .icon {
        width: auto;
        height: 100%;
    }
}