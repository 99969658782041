.banner-subscribe {
    @include media-breakpoint-up(lg) {
        background-color: inherit;
        color: $coal;

        .banner {
            border-top: 1px solid #F7F4EA;
            border-bottom: 1px solid #F7F4EA;
        }
    }

    @include media-breakpoint-down(lg) {
        background-color: inherit;
        color: $coal;

        span {
            display: inline;
        }

        .banner {
            // border-top: 1px solid #F7F4EA;
            // border-bottom: 1px solid #F7F4EA;

            .btn-sm {
                font-size: .7rem;
            }
        }
    }
}

.register-banner {
    @media only screen and (max-width: 320px) {
        height: auto !important;
    }

    .content {
        @include media-breakpoint-down(md) {
            position: relative;
            padding: 24px 32px !important;
            z-index: 1;
        }
    }

    .close {
        z-index: 1;
    }
}