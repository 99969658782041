.guide-hero {
    img {
        @include media-breakpoint-up(lg) {
            height: 625px;
            width: 100%;
        }

        @include media-breakpoint-down(lg) {
            height: 100vw;
            width: 100%;
        }
    }
}

.guide-category-filter {
    z-index: 3;

    .swiper-slide {
        width: auto !important;
    }

    .swiper-wrapper {
        width: 100%;
        max-width: 100%;
        max-height: 100vh;
        min-height: 0;
        min-width: 0;
    }

    .form-check-input {
        border-radius: 0px;
        border: 1px solid white;
        background-color: transparent;
        margin-bottom: 0.25rem;

        @include media-breakpoint-up(lg) {
            width: 24px;
            height: 24px;
        }

        @include media-breakpoint-down(lg) {
            width: 18px;
            height: 18px;
        }

        &:focus,
        &:active {
            border: 1px solid white;
        }
    }

    label {
        padding-left: 8px;
        user-select: none;
    }

    .form-check-input:checked[type=checkbox] {
        border: 1px solid white;
        background-color: white;
        background-image: url('../../../public/img/svg/check-icon-filter.svg');
    }

    .swiper-button-prev {
        background-color: $laurel;
        left: 0;
        padding-right: 8px;

        &.swiper-button-disabled {
            display: none;
        }

        &::after {
            font-size: 26px;
            color: $white;
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .swiper-button-next {
        background-color: $laurel;
        right: 0;
        padding-left: 8px;

        &.swiper-button-disabled {
            display: none;
        }

        &::after {
            font-size: 26px;
            color: $white;
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}

.guide-header-category {
    height: 300px;

    .icon {
        max-height: 128px;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
}

.guide-title-category {
    overflow: auto;

    .icon {
        max-height: 128px;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
}

.stamp-preview-article {
    width: 45% !important;
    height: auto !important;
}

.review-images {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 60vh;

    @include media-breakpoint-down(lg) {
        width: 100%;
        height: 40vh;
        max-height: 40vh;
        object-fit: cover;
    }
}

.review-head {
    @include media-breakpoint-up(lg) {
        max-height: 60vh;

    }

    @include media-breakpoint-down(lg) {
        height: 40vh;
        max-height: 40vh;
        object-fit: cover;
    }
}

.projectOverlayNav {
    z-index: 1;
    width: 100%;
    background-color: rgba(246, 244, 234, 0.80);
    font-size: 1rem;
}

.reviewSlideImage {
    width: 100%;
    height: 100%;
    max-height: 65vh;
    position: relative;

    @include media-breakpoint-only(sm) {
        max-height: 15vh;
    }

    @include media-breakpoint-only(md) {
        max-height: 40vh;
    }

    @include media-breakpoint-only(lg) {
        max-height: 19vh;
    }

    @include media-breakpoint-up(xl) {
        max-height: 23vh;
    }

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }

    .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(35, 35, 35, 0.5);
        z-index: 2;
    }
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.react-horizontal-scrolling-menu--scroll-container:last-child,
.react-horizontal-scrolling-menu--scroll-container:last-child {
    margin-right: 0 !important;
}

.react-horizontal-scrolling-menu--wrapper {
    position: relative;
}

.react-horizontal-scrolling-menu--arrow-left,
.react-horizontal-scrolling-menu--arrow-right {
    z-index: 999;
}

.react-horizontal-scrolling-menu--scroll-container:hover {
    cursor: grab !important;
}

.react-horizontal-scrolling-menu--scroll-container:active {
    cursor: grabbing !important;
}

.react-horizontal-scrolling-menu--separator {
    margin-right: 35px !important;
}

.modal-content {
    border-radius: 0px !important;
    border: none;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
}

.subscribe-modal-container {

    .modal-dialog {
        @include media-breakpoint-down(lg) {
            max-width: 100vw !important;
        }
    }

    @include media-breakpoint-down(lg) {
        .modal-dialog {
            margin: 0;

            .modal-content {
                width: 100vw;
                height: 100vh;
                border-radius: 0 !important;
            }
        }
    }

    .subscribe-modal {
        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 0% 100%;
        }

        @include media-breakpoint-down(xl) {
            height: 80vh;
        }

        @include media-breakpoint-up(xl) {
            .h1 {
                font-size: $h1-font-size;
            }

            .validate {
                .h1 {
                    font-size: $h1-font-size !important;
                }
            }
        }


        @include media-breakpoint-down(xl) {
            .h1 {
                font-size: $h1-font-size * 2.075;
            }

            .h5 {
                font-size: $h5-font-size * 1.35;
            }
        }

        @include media-breakpoint-only(lg) {
            .validate {
                .h1 {
                    font-size: $h2-font-size * 1.075 !important;
                }
            }

            .h1 {
                font-size: $h1-font-size * 1.35;
            }

            .h5 {
                font-size: $h5-font-size * 0.9;
            }
        }

        @include media-breakpoint-only(md) {
            .validate {
                .h1 {
                    font-size: $h1-font-size !important;
                }

                .h5 {
                    font-size: $h5-font-size !important;
                }
            }

            .h1 {
                font-size: $h1-font-size * 2.15;
            }

            .h5 {
                font-size: $h5-font-size * 1.525;
            }

            input,
            button {
                font-size: $font-size-base * 1.4;
            }
        }

        @include media-breakpoint-down(md) {
            .validate {
                .h1 {
                    font-size: $h2-font-size !important;
                }
            }

            .h1 {
                font-size: $h1-font-size * 1.175;
            }

            .h5 {
                font-size: $h5-font-size * .8;
            }
        }


        .modal-form {
            input {
                border: none;
                border-radius: 0;
                border-bottom: 1px solid rgba(46, 42, 43, 0.5);
                background-color: inherit;
                color: $coal;
                font-family: "norms";
                box-shadow: none;
                transition: .225s;
                padding: 0.375rem 0.225rem;

                &:hover,
                &:focus {
                    border-bottom: 1px solid rgba(46, 42, 43, 1);
                    transition: .225s;
                }

                @include media-breakpoint-down(lg) {
                    border: none;
                    border-radius: 0;
                    border-bottom: 2px solid rgba(255, 255, 255, 0.75);
                    color: white;
                    transition: .225s;

                    &:hover,
                    &:focus {
                        border-bottom: 2px solid rgb(255, 255, 255);
                        transition: .225s;
                    }
                }
            }

            .modal-btn-size {
                min-width: 260px;

                @include media-breakpoint-down(md) {
                    min-width: 255px !important;
                }
            }

            .facebook {
                background-color: inherit;
                color: #1877F2 !important;
                border: 1px solid $coal !important;

                @include media-breakpoint-down(lg) {
                    color: rgb(255 255 255 / 80%) !important;
                    border: 1px solid rgb(255 255 255 / 80%) !important;
                }

                .logo {
                    width: 22px;
                    height: 22px;
                    background-image: url('../../../public/img/svg/facebook-logo.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }


            .google {
                background-color: inherit;
                color: #707070;
                border: 1px solid $coal !important;

                @include media-breakpoint-down(lg) {
                    color: rgb(255 255 255 / 80%) !important;
                    border: 1px solid rgb(255 255 255 / 80%) !important;
                }

                .logo {
                    width: 20px;
                    height: 20px;
                    background-image: url('../../../public/img/svg/google.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }

            .apple-logo {
                width: 22px;
                height: 22px;
                background-image: url('../../../public/img/svg/appleid.svg');
                background-repeat: no-repeat;
                background-size: contain;
            }

            .tzo {
                background-color: inherit;
                color: #707070;
                border: 1px solid $coal !important;

                @include media-breakpoint-down(lg) {
                    color: rgb(255 255 255 / 80%) !important;
                    border: 1px solid rgb(255 255 255 / 80%) !important;
                }
            }

            .close {

                @include media-breakpoint-up(lg) {
                    top: 20px;
                    right: 25px;

                    svg {
                        transition: .225s;
                    }

                    &:hover svg {
                        color: $coal;
                        transition: .225s;
                    }
                }

                @include media-breakpoint-down(lg) {
                    top: 15px;
                    right: 20px;

                    svg {
                        width: 22px;
                        height: auto;
                        color: white;
                    }
                }

                @include media-breakpoint-down(md) {
                    svg {
                        width: 16px;
                    }
                }
            }
        }

        .overlay {
            background-color: rgba(0, 0, 0, 0.60);
            width: 100%;
        }
    }
}

.category-card {

    .list {
        span {

            @include media-breakpoint-only(lg) {
                font-size: $font-size-base * 1.25;
            }

            @include media-breakpoint-only(xl) {
                font-size: $font-size-base;
            }

            @include media-breakpoint-up(xxl) {
                font-size: $font-size-base * 1.045;
            }
        }

        img {
            width: 32px;
        }

        .arrow {
            opacity: 0;
            transition: .225s;

            @include media-breakpoint-down(lg) {
                opacity: 1;
            }

            @include media-breakpoint-up(xxl) {
                width: 12px;
            }

            @include media-breakpoint-only(xl) {
                width: 12px;
            }

            @include media-breakpoint-only(lg) {
                width: 16px;
            }

            @include media-breakpoint-down(lg) {
                width: 12px;
            }
        }

        .item:hover>.arrow {
            opacity: 1;
            transition: .225s;
        }
    }
}

.guide-custom-cards {
    .bottom-card {
        max-height: 100px !important;
        height: auto !important;
    }
}

.search-custom-card {
    .photo-lg {
        @include media-breakpoint-up(lg) {
            height: 290px !important;
        }
    }
}