.hs-form {

  .hs-input {
    display: block;
    width: 100%;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;

    height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    @include font-size($input-font-size-sm);
    @include border-radius($input-border-radius-sm);

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius, 0);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    // Remove select outline from select box in FF
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $input-color;
    }

    // Placeholder
    &::placeholder {
      color: $input-placeholder-color;
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }
  }

  label {
    padding-top: add($input-padding-y, $input-border-width);
    padding-bottom: add($input-padding-y, $input-border-width);
    margin-bottom: 0; // Override the `<label>/<legend>` default
    @include font-size(inherit); // Override the `<legend>` default
    line-height: $input-line-height;
  }

  textarea {
    height: 4em !important;
    width: 100%;
  }

  ul {
    &.no-list {
      list-style: none;
    }
  }

  .inputs-list {
    margin: 0 0 5px;
    width: 100%;
    padding-left: 5px;

    label {
      float: none;
      width: auto;
      padding: 0;
      line-height: 18px;
      white-space: normal;
      font-weight: normal;
    }
  }

  .hs-submit {
    text-align: center;
  }

  .hs-button {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    color: $body-color;
    text-align: center;
    white-space: $btn-white-space;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
    @include transition($btn-transition);

    &:hover {
      color: $body-color;
      text-decoration: none;
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      opacity: $btn-disabled-opacity;
      @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      @include box-shadow($btn-active-box-shadow);

      &:focus {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }

    box-shadow: $btn-focus-box-shadow;

    margin-top: 20px;
    margin-bottom: 20px;

    &.primary {
      color: $black;
      background-color: $primary;
      border-color: $primary;
    }
  }

  .hs-richtext {
    margin-top: 15px;

    p {
      font-size: 10px;
    }
  }

  .hs-dependent-field {
    .inputs-list {
      list-style-type: none;
      display: contents !important;

      .hs-form-booleancheckbox-display {
        display: flex;

        .hs-input {
          width: auto;
          box-shadow: none;
          font-size: 5px !important;
        }

        span {
          margin-left: 10px;
          font-size: 12px;
        }
      }
    }
  }
}

.hs-error-msgs {
  font-size: 12px;

  label {
    color: #f2545b
  }
}

.hs-form-required {
  color: red;
}

.submitted-message {
  margin-bottom: 20px;
  font-weight: bold;
}

.hs-cta-img {
  max-width: 100%;
  height: auto;
  border-radius: $border-radius-lg;
}


// POPUPS
#leadinModal-1013100.leadinModal {
  &.leadinModal-theme-default.leadinModal-v3 {
    .leadinModal-content {
      width: 60em !important;
      max-height: 100%;

      @include media-breakpoint-down(md) {
        width: 40em !important;
      }
    }

    .dyno-image {
      float: none !important;
      display: block !important;
      margin: 0 auto 10px !important;
      width: 100% !important;
    }
  }

  .dyno-image {
    img {
      max-width: 100% !important;
      max-height: 100% !important;
      border-radius: 5px;
    }
  }

  &.leadinModal-theme-default.leadinModal-v3.leadinModal-preview {
    h4 {
      display: none;
    }

    .advance-wrapper {
      margin-left: 0 !important;
      text-align: center !important;

      a {
        font-weight: 600;
        height: 2.7rem;
        padding: 0.85rem;
        font-size: 16px;
      }
    }
  }
}