.navbar {
    @include media-breakpoint-down(lg) {
        .mobile-topbar {
            position: absolute !important;
        }

        &.hide {
            top: -$mobile-topbar;
            transition: .255s;
        }

        &.show {
            top: 0px;
            transition: .255s;
        }
    }
}

.nav-responsive-fluid {
    min-height: 44px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    z-index: 1030;

    @media only screen and (min-width: 992px) {
        position: absolute;
        top: 100%;
        margin-top: 0;
    }

    @media only screen and (max-width: 992px) {
        top: 100%;
    }
}

.nav-link {
    padding-top: 0;
    padding-bottom: 0;
    color: $white !important;

    &#lang-mobile-dropdown {
        color: $coal !important
    }
}

.navbar-nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

.nav-center-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-toggler {
    padding: 0 0.75rem !important;
    border: none;

    &:focus {
        box-shadow: none;
    }
}

#guide-dropdown {
    a {
        padding-left: 0;
    }

    &:hover {
        #guide-dropdown-content {
            display: block;
        }
    }
}

.guide-dropdown {
    position: absolute;
    left: 0 !important;
    width: 100%;
    background-color: $secondary;
    border: none;
    border-radius: 0;
    box-shadow: 0 0.215rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
}

div[aria-labelledby="collasible-nav-dropdown"] {
    @media only screen and (min-width: 992px) {
        top: 32px !important;
    }

    @media only screen and (max-width: 992px) {
        top: 49px !important;
    }
}

#nav-tags {
    .dropdown-item:hover {
        background-color: transparent;
        font-weight: bolder;
    }

    .dropdown-item:focus {
        background: inherit;
    }

    .dropdown-item {
        font-size: 1rem;
    }
}

div[aria-labelledby="guideDropdown"] {
    background-color: $secondary;
    border-radius: 3px;
    border: none;
}

#user-nav-dropdown {
    padding-right: 0 !important;

    &::after {
        display: none !important;
    }
}

.dropdown-item:active {
    color: $coal;
    background: transparent;
}

.mobile-topbar {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    transition: .35s;

    .nav-section {
        flex-basis: 0;

        .dropdown-menu {
            z-index: 1040;
        }
    }

    .tzo-logo {
        margin: 0;
        padding: 0;

        img {
            width: 155px;
        }
    }

    #lang-mobile-dropdown {
        padding: 0;
    }

    div[aria-labelledby="lang-mobile-dropdown"] {
        margin: 0;
        padding: 0;
        border: none;
        overflow: hidden;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1), 0px -1px 2px 0.25px rgba(0, 0, 0, 0.1);
        left: 50% !important;
        transform: translateX(-50%) !important;

        a {
            font-family: 'inter-regular';

            &:hover {
                background-color: $primary;
                color: $coal;
            }
        }
    }

    .nav-content {
        width: 100%;
        height: calc(100dvh - 53px);
        position: absolute;
        top: 53px;
        transform: translateX(100%);
        background-color: $white;
        z-index: 1030;
        transition: .35s;

        &.open {
            transform: translateX(0%);
            transition: .35s;
        }

        .rrss {
            opacity: 50%;

            &:hover {
                opacity: 75%;
            }
        }

        .store-button {
            width: 175px;
        }
    }

    .searcher {
        border: 1px solid #c4c4c4;

        input,
        button {
            opacity: 50%;
            background-color: inherit;

            &:focus {
                opacity: 75%;
            }
        }

        &:focus-within {
            border: 1px solid rgba(46, 42, 43, 0.625);

            button {
                opacity: 75%;
            }
        }
    }

    .header {
        transition: .225s;
        color: $coal !important;
        user-select: none;

        svg {
            color: $coal !important;
            transition: .225s;
        }

        &.active {
            background-color: $coal;
            color: white !important;
            transition: .225s;

            svg {
                color: white !important;
                transition: .225s;
            }
        }
    }

    .navbar-dropdown {
        user-select: none;
        transition: .225s;

        .open {
            &.header {
                background-color: $dark-brown;
                color: white !important;
                transition: .225s;

                svg {
                    color: white !important;
                    transition: .225s;
                }
            }
        }

        .item {
            transition: .225s;

            &:hover {
                background-color: $primary;
                color: $coal;
                transition: .225s;
            }
        }

        .flip-icon {
            transform: scale(1, -1);
        }
    }

    .footer {
        font-size: 12px;
    }
}