a {
    color: $black;
}

a:hover {
    color: $dark;
}

.link-btn {

    &:hover {
        color: #000 !important;
        background-color: #f9fafc;
        border-color: #f9fafc;
    }

}

.footerLink {
    color: white;
    text-decoration: none;
}

.footerLink:hover {
    color: #d8d8d8;
}

.footerLink-sand {
    color: #F6F4EA;
    text-decoration: none;
}

.footerLink-sand:hover {
    color: #e0dfd8;
}

.footerLink-gray {
    color: #6c6c6c;
    text-decoration: none;
}

.footerLink-gray:hover {
    color: #8a8a8a;
}

.footer-text-gray {
    color: #6c6c6c;
}

.footer-separator {
    color: #6c6c6c
}