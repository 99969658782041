.card-slider-container {
    .react-horizontal-scrolling-menu--arrow-left {
        position: absolute !important;
        transform: translateY(-50%) !important;
        left: 0 !important;
        top: 50% !important;
        height: 100%;
        // margin-left: 4px;

        .custom-control {
            background-color: rgba(0, 0, 0, 0.6) !important;
            // border-radius: 50%;

            svg {
                color: rgb(230, 230, 230) !important;
                width: 14px !important;
            }
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .react-horizontal-scrolling-menu--arrow-right {
        position: absolute !important;
        transform: translateY(-50%) !important;
        right: 0 !important;
        top: 50% !important;
        height: 100%;
        // margin-right: 4px;

        .custom-control {
            background-color: rgba(0, 0, 0, 0.6) !important;
            // border-radius: 50%;

            svg {
                color: rgb(230, 230, 230) !important;
                width: 14px !important;
            }
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .content-card {

        width: 256px;

        .bottom-card {
            height: auto !important;
        }

        @include media-breakpoint-up(lg) {
            .photo-lg {
                width: 100%;
                height: 255px;

                @include media-breakpoint-only(lg) {
                    height: 156px !important;
                }

                object-fit: cover;
            }

            .photo-sm {
                width: 100%;
                height: 360px;
                object-fit: cover;
            }
        }
    }

    .review-content-card {}
}

.card-slider {
    .react-horizontal-scrolling-menu--separator {
        margin-right: .55rem !important;
    }

    .react-horizontal-scrolling-menu--item {
        padding: 0px .2rem;
    }
}

.review-content-card {

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px -2px 3px 0px rgba(0, 0, 0, 0.10);
    width: 256px;

    img {
        user-select: none;
    }

    .react-horizontal-scrolling-menu--arrow-left {
        position: absolute !important;
        transform: translateY(-50%) !important;
        left: 0 !important;
        top: 50% !important;
        margin-left: 4px;

        .custom-control {
            background-color: $coal !important;
            border-radius: 50%;

            svg {
                color: $white !important;
                width: 14px !important;
            }
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .react-horizontal-scrolling-menu--arrow-right {
        position: absolute !important;
        transform: translateY(-50%) !important;
        right: 0 !important;
        top: 50% !important;
        margin-right: 4px;

        .custom-control {
            background-color: $coal !important;
            border-radius: 50%;

            svg {
                color: $white !important;
                width: 14px !important;
            }
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    @include media-breakpoint-down(lg) {

        .content-lg {
            width: 100%;
            height: 320px;
            object-fit: cover;

            .suggest-text {
                font-size: $small-font-size;
            }

            .suggest {
                font-size: 13px;
            }
        }

        .content-sm {
            width: 100%;
            height: 160px;
            object-fit: cover;

            .suggest-text {
                font-size: $small-font-size;
            }

            .suggest {
                font-size: 13px;
            }
        }

        .category {
            width: auto;
            height: 26px;
        }

        .photo-lg {
            width: 100%;
            height: 320px;
            object-fit: cover;
        }

        .photo-sm {
            width: 100%;
            height: 160px;
            object-fit: cover;
        }

        .photo {
            width: 100%;
            height: 260px;
            object-fit: cover;
        }

        .card-title-sm {
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .suggest-title {
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .fav-icon {
            max-height: 24px;

            path {
                fill: none;
                stroke-width: 42px;
                stroke: $coal;
            }

            &.hover {
                opacity: 0;
                transition: .225s;

                path {
                    fill: $light-coal;
                }
            }

            &:hover.hover {
                opacity: 1;
                transition: .225s;
            }
        }

        .card-dropdown {
            user-select: none;

            &.close {
                display: none;
            }

            &.open {
                display: flex;
                flex-direction: column;
            }

            .option-sm {
                font-size: 13px;
                transition: .225s;
                cursor: pointer;

                &:hover {
                    background-color: $coal;
                    color: $white;
                    transition: .225s;
                }
            }
        }

        .suggest-body {
            width: 100%;
            height: 294px; // 260px img height + 34 top card
        }

        .suggest-title {
            font-size: $font-size-base;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    @include media-breakpoint-up(lg) {

        .content-lg {
            width: 100%;
            height: 360px;
            object-fit: cover;

            .suggest-text {
                font-size: $small-font-size;
            }

            .suggest {
                font-size: 13px;
            }
        }

        .content-sm {
            width: 100%;
            height: 360px;
            object-fit: cover;

            .suggest-text {
                font-size: $h3-font-size;
            }

            .suggest {
                font-size: $font-size-base;
            }
        }

        .category {
            width: auto;
            height: 26px;
        }

        .photo {
            width: 100%;
            height: 256px;
            object-fit: cover;
        }

        .suggest-body {
            width: 100%;
            height: 286px;
        }

        .card-title-sm {
            font-size: $font-size-base;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .suggest-title {
            font-size: $font-size-base;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .fav-icon {
            max-height: 24px;
            transition: .225;

            path {
                fill: none;
                stroke-width: 42px;
                stroke: $coal;
                transition: .225;
            }

            &.hover {
                opacity: 0;
                transition: .225s;

                path {
                    fill: $light-coal;
                }
            }

            &:hover.hover {
                opacity: 1;
                transition: .225s;
            }
        }

        .card-dropdown {
            user-select: none;

            &.close {
                display: none;
            }

            &.open {
                display: flex;
                flex-direction: column;
            }

            .option-sm {
                transition: .225s;
                cursor: pointer;

                &:hover {
                    background-color: $coal;
                    color: $white;
                    transition: .225s;
                }
            }
        }
    }

    .top-card {
        height: 30px;
    }

    .bottom-card {
        height: 64px;
    }
}