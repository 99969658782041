.villa-card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // height: 100%;
    // border-radius: $tzo-radius;
    background-color: inherit;

    &:hover {
        .new-link-dark-green::before {
            width: 0%;
            transition: 0.225s;
        }

        .new-link-laurel::before {
            width: 0%;
            transition: 0.225s;
        }

        .new-link-coal::before {
            width: 0%;
            transition: 0.225s;
        }
    }

    .slider {
        position: relative;
        width: 100%;

        @include media-breakpoint-up(lg) {
            height: 350px;
        }

        @include media-breakpoint-down(lg) {
            height: 300px;
        }

        &.featured {
            width: 385px;
            height: 335px;
        }

        img {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0;
            user-select: none;
            transition: opacity .5s;

            &.active {
                opacity: 1;
                transition: opacity .5s;
            }
        }
    }

    .price-tag {
        z-index: 2;
        position: absolute;
        top: 20px;
        right: 0;
        background-color: white;
        padding: .4rem .75rem;
        box-shadow: 0px 3.68681px 3.68681px 0px rgba(0, 0, 0, 0.25);
        ;
    }

    .arrow {
        z-index: 2;
        filter: drop-shadow(0px 0px 7.56930160522461px #000);
        cursor: pointer;

        &.left {
            padding-left: 20px !important;
            left: 0;
        }

        &.right {
            right: 0;
            padding-right: 20px !important;
        }
    }

    .dot-control {
        bottom: 14px;

        .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: white;
            box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
            opacity: .45;
            transition: .255s;

            &.active {
                opacity: 1;
                transition: .255s;
            }

            &:hover {
                opacity: 1;
                transition: .225s;
            }
        }
    }

    &-placeholder {
        background-color: white;

        .slider {
            position: relative;
            width: 100%;

            @include media-breakpoint-up(lg) {
                height: 350px;
            }

            @include media-breakpoint-down(lg) {
                height: 300px;
            }
        }

        .icon {
            width: 22px;
            height: 20px;
        }

        .rate {
            width: 48px;
            height: 20px;
        }
    }
}

.villa-header {
    @include media-breakpoint-up(lg) {
        min-height: 78vh;
        height: 0px;
    }

    .villa-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 1;
    }

    .villa-overlay {
        position: relative;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        z-index: 10;

        @include media-breakpoint-up(lg) {
            .h1 {
                font-size: $h1-font-size * 1.75;
            }
        }

        @include media-breakpoint-down(lg) {
            .h1 {
                font-size: $h1-font-size
            }

            .h2 {
                font-size: $h5-font-size
            }
        }

        .container {
            gap: 9rem;
        }
    }

    .featured {
        background-color: rgba(255, 255, 255, 0.5);

        .villa-card {
            width: 385px;
        }
    }
}

.tmp-card {
    width: 305px;
    height: 360px;
    background-color: $gray-600;
    border-radius: $tzo-radius;
}

.villa-input {
    flex-basis: 0;

    .villa-select {
        border-color: rgba(46, 41, 42, 0.35) !important;
        background-color: $white;
        transition: .225s;

        &.open {
            border-end-start-radius: 0px;
            border-end-end-radius: 0px;
            transition: .225s;
        }
    }



    .options-container {
        z-index: 10;

        &.close {
            opacity: 0;
            background-color: white;
            // overflow: hidden;
            transition: opacity .225s;
        }

        &.open {
            opacity: 1;
            height: auto !important;
            transition: opacity .225s;
        }

        ul {
            padding: 0;
            list-style: none;
            list-style-type: none;
            margin: 0;
            width: 100%;

            li {
                padding: 0 1.5rem;
                user-select: none;
                cursor: pointer;
                font-family: "norms-bold";
                color: $coal;
                transition: background .255s;
                height: auto !important;

                .option {
                    padding: 0.75rem 0rem;
                }

                &.select:hover {
                    background-color: rgb(246, 246, 246);
                    transition: .225s;
                }

                &:not(:last-child) {
                    .option {
                        border-bottom: 1px solid rgba(46, 41, 42, 0.35);
                    }
                }

                .control {
                    background-color: inherit !important;
                }
            }
        }

        &.calendar {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }

}

.react-daterange-picker {
    position: absolute !important;
    top: 100%;
    left: 0;
    width: 100%;

    abbr,
    span,
    button {
        font-family: "inter-medium" !important;
    }

    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
        display: none !important;
    }

    .react-calendar {
        border: none !important;
        overflow: hidden;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
        padding: 1.25rem;
        width: 100% !important;
    }

    .react-calendar__month-view__weekdays {
        abbr {
            text-decoration: none;
            cursor: auto;
            user-select: none;
            color: $coal;
            font-family: "norms-bolder";
        }
    }

    .react-calendar__tile--active {
        color: white !important;
    }

    .react-daterange-picker__calendar--closed {
        display: block !important;
    }

    .react-calendar__navigation button:disabled {
        background-color: inherit;
    }

    .react-calendar__tile--hasActive {
        background-color: #006edc;
        color: $white;
    }
}

.react-daterange-picker__wrapper {
    display: none !important;
}

.react-daterange-picker__calendar {
    inset: unset !important;
    top: 100% !important;
    width: 100% !important;
    z-index: 10 !important;
}

.villa-info-section {
    .title {
        @include media-breakpoint-up(lg) {
            font-size: $h1-font-size * 1.25;
        }

        @include media-breakpoint-down(lg) {
            font-size: $h6-font-size;
        }
    }

    .title-image {
        @include media-breakpoint-down(lg) {
            height: 56px;
        }

        @include media-breakpoint-up(lg) {
            height: 220px;
        }
    }

    .picture {
        height: 100%;
        width: 100%;
        object-fit: cover;

        @include media-breakpoint-up(lg) {
            max-height: 520px;
        }

        @include media-breakpoint-down(lg) {
            max-height: 320px;
        }
    }
}

.villa-article {
    .picture {
        height: 100%;
        width: 100%;
        object-fit: cover;

        @include media-breakpoint-up(lg) {
            max-height: 520px;
        }

        @include media-breakpoint-down(lg) {
            height: 280px;
        }
    }
}

.villa-banner {
    position: relative;

    .banner-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        top: 0;
        z-index: 1;
    }

    .content {
        position: relative;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        z-index: 10;

        @include media-breakpoint-down(lg) {
            .h1 {
                font-size: $h4-font-size;
            }

            .h3 {
                font-size: $h5-font-size;
            }
        }

        .card {
            border-radius: 0px !important;

            @include media-breakpoint-up(lg) {
                width: 280px !important;
            }
        }
    }
}

.villa-searcher-container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        overflow: hidden;
        padding: 1.5rem 0rem;
        height: calc(100dvh - 101px);
    }

    @include media-breakpoint-down(lg) {
        padding: 0;
        min-height: calc(100dvh - 101px);
    }

    .filters {
        display: flex;
        flex-direction: row;

        .options {
            height: 65px;
            width: 60px;
            overflow: hidden;
            box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
            transition: .425s;

            &.active {
                width: 30%;
                box-shadow: none;
                transition: .425s;
            }

            .collapse {
                opacity: 0;
                transition: .255s;

                &.active {
                    opacity: 1;
                    transition: .255s;
                }
            }

            // .flip {
            //     transform: rotate(180deg);
            // }
        }

        .list {
            width: auto;
            height: 65px;
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            justify-content: space-between;
            align-items: center;
            border-left: 1px solid rgba(46, 41, 42, 0.35) !important;
            border-bottom: 1px solid rgba(46, 41, 42, 0.35) !important;
            overflow-x: hidden;
            transition: .5s;
        }

        .react-daterange-picker {
            width: 100% !important;

            &__calendar {
                width: 100% !important;
            }
        }

    }

    .content {
        .filters {
            width: 30%;
            max-height: 100%;
            height: 100%;
            display: flex;
            position: absolute;
            top: 0;
            left: -30%;
            background-color: $white;
            z-index: 5;
            // overflow-x: hidden;
            transition: left .425s;
            border-right: 1px solid rgba(46, 41, 42, 0.35);


            &.active {
                transition: left .425s;
                border-right: 0px !important;
                left: 0;
            }

            .filter-container {
                max-height: calc(100%);
                overflow: hidden;
                // min-height: 100%;

                .rc-slider {
                    height: 2px;
                }

                .rc-slider-rail {
                    background-color: #D9D9D9 !important;
                    height: 2px;
                }

                .rc-slider-track {
                    background-color: #5A5C69;
                    height: 2px;
                }

                .rc-slider-handle {
                    border-color: #5A5C69;
                    border-width: 2px;
                    background-color: $white;
                    opacity: 1;
                    margin-top: -6px;

                    &:active {
                        box-shadow: 0 0 5px $primary;
                    }

                    &-dragging {
                        box-shadow: 0 0 0 5px $primary !important;
                        border-color: #5A5C69 !important;
                    }

                    &-1 {
                        transform: translateX(0%) !important;
                    }

                    &-2 {
                        transform: translateX(-50%) !important;
                    }
                }

                .filter-box {
                    .filter-content {
                        overflow-y: auto;
                        overflow-x: hidden;
                    }
                }

                .form-check {
                    padding-left: 1.75rem;

                    &-input {
                        margin-top: .75em;
                        width: 1.25em;
                        height: 1.25em;
                        border-radius: $tzo-radius;
                        transform: translateY(-50%);
                    }

                    &-label {
                        padding-left: .25rem;
                        font-family: "norms-bold";
                        user-select: none;
                    }
                }

                .control {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .villa-list {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            transition: width .425s;

            // .slider {
            //     height: 285px;
            // }

            &.active {
                width: 70%;
                border-left: 1px solid rgba(46, 41, 42, 0.35) !important;
                overflow-x: hidden;
                transition: width .425s;
            }
        }
    }
}

.villa-searcher {
    @include media-breakpoint-up(lg) {
        height: 100%;
        width: 100%;
        flex-direction: column;
        flex-grow: 1;
        border: 1px solid rgba(46, 41, 42, 0.35) !important;
        overflow: hidden;

        .filter {
            border-right: 1px solid rgba(46, 41, 42, 0.35) !important;
        }

        .content {
            max-height: calc(100% - 65px);
            display: flex;
            flex-direction: column;

            .list {
                overflow-y: auto;
            }
        }

        .control {
            width: 28px;
            height: 28px;
        }

        .head {
            height: 65px;
        }
    }

    @include media-breakpoint-down(lg) {
        min-height: calc(100dvh - 60px);
    }
}

.villa-mobile-filters {
    .form-check {
        padding-left: 1.75rem;

        &-input {
            margin-top: .75em;
            width: 1.25em;
            height: 1.25em;
            border-radius: 0;
            transform: translateY(-50%);
        }

        &-label {
            padding-left: .25rem;
            font-family: "inter-regular";
            user-select: none;
        }
    }

    .control {
        width: 24px;
        height: 24px;
    }
}

.filter-range-button:hover {

    #filter-range-circle-1,
    #filter-range-circle-2,
    #filter-range-circle-3 {
        fill: $primary;
        transition: .225s;
    }
}

#filter-range {
    &-circle-1 {
        cx: 7.4082;
        transition: .225s ease-in-out;
    }

    &-circle-2 {
        cx: 22.4082;
        transition: .225s ease-in-out;
    }

    &-circle-3 {
        cx: 7.4082;
        transition: .225s ease-in-out;
    }

    &.active {
        #filter-range-circle-1 {
            cx: 22.4082;
            transition: .225s ease-in-out;
        }

        #filter-range-circle-2 {
            cx: 7.4082;
            transition: .225s ease-in-out;
        }

        #filter-range-circle-3 {
            cx: 22.4082;
            transition: .225s ease-in-out;
        }
    }
}

#filter-grid {
    path {
        transition: fill .225s ease-in-out;
    }

    &:hover {
        path {
            fill: $primary !important;
            transition: .225s ease-in-out;
        }
    }

    &.active {
        path {
            fill: $primary !important;
            transition: .225s ease-in-out;
        }
    }
}

#filter-list {
    path {
        transition: fill .225s ease-in-out;
    }

    &:hover {
        path {
            fill: $primary !important;
            transition: .225s ease-in-out;
        }
    }

    &.active {
        path {
            fill: $primary !important;
            transition: .225s ease-in-out;
        }
    }
}

#filter-map-icon {
    transition: .225s ease-in-out;

    &:hover {
        .background {
            transition: fill .225s ease-in-out;
            fill: $primary !important;
        }
    }

    .background {
        transition: fill .225s ease-in-out;
    }

    &.active {
        .background {
            transition: fill .225s ease-in-out;
            fill: $primary !important;
        }
    }
}

.filter-map {
    .map {
        &.active {
            fill: rgba(33, 158, 110, 0.705) !important;
            stroke-width: 1;
            stroke: rgb(0, 119, 76);
        }

        &:hover {
            fill: $primary;
            stroke-width: 1;
            // stroke: rgb(89, 91, 105);
            stroke: #b3af0b;
        }
    }


    .map-label {
        color: rgba(33, 158, 110, 0.65);
        position: absolute;
        bottom: 4px;
        left: 0;
    }
}

.villa-detailed-card {
    .slider {
        position: relative;
        height: 245px;
        width: 245px;
        object-fit: cover;

        img {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0;
            user-select: none;
            transition: opacity .5s;

            &.active {
                opacity: 1;
                transition: opacity .5s;
            }
        }
    }

    .arrow {
        z-index: 2;
        filter: drop-shadow(0px 0px 7.56930160522461px #000);
        cursor: pointer;

        &.left {
            left: 20px;
        }

        &.right {
            right: 20px;
        }
    }

    .dot-control {
        bottom: 14px;

        .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: white;
            box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
            opacity: .45;
            transition: .255s;

            &.active {
                opacity: 1;
                transition: .255s;
            }

            &:hover {
                opacity: 1;
                transition: .225s;
            }
        }
    }

    .rating {
        width: 44px;
        height: 37.5px;
    }
}

.villa-filter-container {
    overflow: hidden;
    // min-height: 100%;

    .rc-slider {
        height: 2px;
    }

    .rc-slider-rail {
        background-color: #D9D9D9 !important;
        height: 2px;
    }

    .rc-slider-track {
        background-color: #5A5C69;
        height: 2px;
    }

    .rc-slider-handle {
        border-color: #5A5C69;
        border-width: 2px;
        background-color: $white;
        opacity: 1;
        margin-top: -6px;

        &:active {
            box-shadow: 0 0 5px $primary;
        }

        &-dragging {
            box-shadow: 0 0 0 5px $primary !important;
            border-color: #5A5C69 !important;
        }

        &-1 {
            transform: translateX(0%) !important;
        }

        &-2 {
            transform: translateX(-50%) !important;
        }
    }

    .filter-box {
        .filter-content {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .form-check {
        padding-left: 1.75rem;

        &-input {
            margin-top: .75em;
            width: 1.25em;
            height: 1.25em;
            border-radius: 0;
            transform: translateY(-50%);
        }

        &-label {
            padding-left: .25rem;
            font-family: "inter-regular";
            user-select: none;
        }
    }

    .control {
        width: 20px;
        height: 20px;
    }
}

.villa-map {
    position: relative;
    height: calc(100dvh - $mobile-topbar);
    width: 100%;

    .price-marker {
        background-color: $white;
        padding: .35rem .65rem;
        border: 1px solid $coal;
        border-radius: 30px;
        transition: .225s;
        z-index: 10;

        &.extend {
            padding: .875rem .65rem .35rem .65rem;
        }

        &:hover {
            background-color: $primary;
            transition: .225s;

            &::after {
                border-top: 12px solid $primary;
                transition: .225s;
            }
        }

        &::after {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 12px solid white;
            content: "";
            height: 0;
            left: 50%;
            position: absolute;
            top: 100%;
            transform: translate(-50%, -18%);
            width: 0;
            z-index: 10;
            transition: .225s;
            // filter: drop-shadow(0 0 0.75rem blue);
        }

        &::before {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 12px solid $coal;
            content: "";
            height: 0;
            left: 50%;
            position: absolute;
            top: 100%;
            transform: translate(-50%) scale(1);
            width: 0;
            z-index: 10;
        }

        .float-tag {
            position: absolute;
            top: 5px;
            left: 12px;
        }
    }
}

.map-menu {
    position: absolute;
    top: 2rem;
    left: 2rem;
    height: 100% !important;
    background-color: white;
    max-height: calc(100% - 4rem);
    z-index: 2;

    .filter-menu {
        transition: width .425s;
        width: 60px;
        overflow: hidden;

        .rc-slider {
            height: 2px;
        }

        .rc-slider-rail {
            background-color: #D9D9D9 !important;
            height: 2px;
        }

        .rc-slider-track {
            background-color: #5A5C69;
            height: 2px;
        }

        .rc-slider-handle {
            border-color: #5A5C69;
            border-width: 2px;
            background-color: $white;
            opacity: 1;
            margin-top: -6px;

            &:active {
                box-shadow: 0 0 5px $primary;
            }

            &-dragging {
                box-shadow: 0 0 0 5px $primary !important;
                border-color: #5A5C69 !important;
            }

            &-1 {
                transform: translateX(0%) !important;
            }

            &-2 {
                transform: translateX(-50%) !important;
            }
        }

        &.active {
            width: 380px;
            transition: width .425s;
            overflow-x: hidden;

            .villa-filter-container {
                width: 380px;
                transform: translateX(0px);
                // transition: transform .475s;
                transition: transform .425s;
            }
        }

        .villa-filter-container {
            width: 380px;
            transform: translateX(-380px);
            // transition: transform .625s;
            transition: transform .425s;
            max-height: calc(100% - 60px);
            position: absolute;
            top: 60px;
        }

        .collapse {
            opacity: 0;
            transition: .255s;

            &.active {
                opacity: 1;
                transition: .255s;
            }
        }
    }

    .content-menu {
        width: 420px;
        background-color: white;
        border-left: 1px solid rgba(46, 41, 42, 0.35);

        .nav-menu {
            border-bottom: 1px solid rgba(46, 41, 42, 0.35);
        }

        .villa-content {
            max-height: calc(100% - 60px);
            height: 100%;
            overflow-y: auto;

            &-slider {
                img {
                    height: 300px;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .swiper {

                .swiper-button-next {
                    background-color: #00000000 !important;
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.35) 15%, rgba(0, 0, 0, 0.75) 100%);

                    &::after {
                        font-size: 18px !important;
                        font-weight: bold;
                    }
                }

                .swiper-button-prev {
                    background-color: #00000000 !important;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.35) 15%, rgba(0, 0, 0, 0.75) 100%);

                    &::after {
                        font-size: 18px !important;
                        font-weight: bold;
                    }
                }
            }

            .villa-detail {}
        }
    }
}

.villa-slider {
    img {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}

.booking-btn {
    &:disabled {
        opacity: 30% !important;
    }
}

.villa-description {
    white-space: pre-line;
}

.villa-location {
    height: 425px;
}

.etv {
    background-color: #449D96;
    width: 95px;
    height: 95px;
}

.tzo-guaranty {
    width: 115px;
    height: 115px;

    img {
        width: 22px;
        height: 22px;
        top: 0px;
        right: -5px;
    }
}


.homerti-guaranty {
    width: 200px;
}

.villa-rating {
    width: 40px;
    height: 33px;
}

.sticky-price {
    z-index: 10;
}

.villa-budget {
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;

    &.sheet {
        @include media-breakpoint-down(lg) {
            transition: .375s;
            transform: translateY(66px);

            &.open {
                transition: .375s;
                transform: translateY(0px);
            }
        }
    }

    &.sticky {
        position: sticky;
    }

    &.fixed {
        position: fixed;
    }
}

.villa-terms-modal {
    .modal-lg {
        max-width: 1150px;
    }

    .content {
        @include media-breakpoint-up(lg) {
            max-height: 400px;
        }

        @include media-breakpoint-down(lg) {
            max-height: 65vh;
        }

        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
            border-radius: 6px;
            border: 2px solid $gray-500;
        }

        &::-webkit-scrollbar-thumb:active {
            background-color: $gray-800;
        }
    }
}

.villa-detail-container {
    padding-bottom: calc(63px + 1.5rem) !important;

    .sticky-container {
        margin-top: 72px;
    }
}

.vacalia-logo {
    width: 45vw;
}

.sheet-content {

    &.price {
        height: calc(100vh - ($mobile-topbar + (54px + 62px))) !important;
    }
}