.carousel {
    flex-direction: column-reverse !important;

    @media only screen and (max-width: 767px) {
        height: 350px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1399px) {
        height: 450px;
    }

    @media only screen and (min-width: 1400px) {
        height: 490px;
    }

    .row {
        height: 100%;
    }
}

.carousel-indicators [data-bs-target] {
    width: calc(1rem - 6px);
    height: calc(1rem - 6px);
    background-color: transparent;
    border-radius: 50%;
    border: 3px solid #C1C1C1;

}

.carousel-indicators {
    margin-top: 16px;

    .active {
        background-color: #C1C1C1;
        border: none;
        width: 1rem;
        height: 1rem;
    }

    @media only screen and (max-width: 1399px) {
        position: static;
    }
}

.carousel-img {
    object-fit: cover;
}

.carousel-caption {
    bottom: 0;
    padding-bottom: 0;

    @media only screen and (max-width: 767px) {
        bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.carousel-item {
    @media only screen and (max-width: 768px) {
        height: 300px;
    }
}

.carousel-inner {
    display: flex;
    height: 100%;

    @media only screen and (min-width: 992px) {
        height: calc(100% - 50px) !important;
    }
}

.shadow-carousel {
    box-shadow: 0 0.215rem 0.25rem 0 rgb(58 59 69 / 20%) !important;
}

.carousel-category {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;

    @media only screen and (max-width: 767px) {
        height: calc(100%);
    }

    @media only screen and (min-width: 768px) and (max-width: 1399px) {
        height: calc(100%);
    }

    @media only screen and (min-width: 1400px) {
        height: calc(100%);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

#home-slider {
    .carousel-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .carousel-wrapper {
        display: flex;
        width: 100%;
        position: relative;
    }
    
    .carousel-content-wrapper {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
    
    .carousel-content {
        display: flex;
        transition: all 250ms linear;
        -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
        scrollbar-width: none;  /* hide scrollbar in Firefox */
    }
    
    /* hide scrollbar in webkit browser */
    .carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
        display: none;
    }
    
    .carousel-content > * {
        width: 100%;
        flex-shrink: 0;
        flex-grow: 1;
    }
    
    .carousel-content.show-2 > * {
        width: 50%;
    }
    
    .carousel-content.show-3 > * {
        width: calc(100% / 3);
    }
    
    .carousel-content.show-4 > * {
        width: calc(100% / 4);
    }
    
    .left-arrow, .right-arrow {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background-color: white;
        border: 1px solid #ddd;
    }
    
    .left-arrow {
        left: 24px;
    }
    
    .right-arrow {
        right: 24px;
    }
    
    @media (hover: none) and (pointer: coarse) {
        .left-arrow, .right-arrow {
            display: none;
        }
    }
}