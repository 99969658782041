.breadcrumb-item {

    a,
    span {
        text-decoration: none !important;
        color: $gray-200 !important;
    }

    span {
        cursor: default;
    }

    a:hover {
        color: $white !important;
    }

    .active {
        color: $white !important;
    }

    &::before {
        color: $white !important;
        content: "·" !important;
    }

    &:first-child::before {
        content: "" !important;
    }
}

.breadcrumb-container {
    background-color: $laurel !important;
    // box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
}

.breadcrumb {
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
        align-items: center;
    }
}