.header-review {
    @include media-breakpoint-up(md) {
        height: 45vh;

        p {
            font-size: $h1-font-size * 1.75;
        }
    }

    @include media-breakpoint-down(md) {
        height: 55vh;

        p {
            font-size: $h1-font-size * 1.015;
        }
    }

    .title-overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.35);
    }

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
}

.categoryIcon {
    width: 48px;
    height: auto;

    @include media-breakpoint-only(lg) {
        width: 42px;
        height: auto;
    }
}

.slider-large-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.site-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .15s;
    border-color: $dark-gray !important;

    @include media-breakpoint-up(md) {

        svg,
        img {
            width: 24px;
            height: 24px;
        }
    }

    svg,
    img {
        width: 22px;
        height: 22px;
        transition: .15s;
        color: $dark-gray !important;
    }


}

.related-sites {
    .title {
        line-height: 1.2 !important;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media only screen and (max-width: 575px) {
            font-size: $h5-font-size;
            -webkit-line-clamp: 2 !important;
        }

        @media only screen and (min-width: 576px) and (max-width: 991px) {
            font-size: 1.35rem;
            -webkit-line-clamp: 2 !important;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
            font-size: $h5-font-size;
            -webkit-line-clamp: 2 !important;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
            font-size: $h6-font-size;
            -webkit-line-clamp: 2 !important;
        }

        @media only screen and (min-width: 1200px) and (max-width: 1399px) {
            font-size: $h5-font-size;
            -webkit-line-clamp: 2 !important;
        }

        @media only screen and (min-width: 1400px) and (max-width: 1919px) {
            font-size: $h5-font-size;
            -webkit-line-clamp: 2 !important;
        }

        @media only screen and (min-width: 1920px) {
            font-size: $h5-font-size;
            -webkit-line-clamp: 2 !important;
        }
    }

    .text {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media only screen and (max-width: 575px) {
            font-size: 0.9rem;
            -webkit-line-clamp: 6 !important;
        }

        @media only screen and (min-width: 576px) and (max-width: 767px) {
            font-size: 0.85rem;
            -webkit-line-clamp: 6 !important;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
            font-size: 0.85rem;
            -webkit-line-clamp: 5 !important;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
            font-size: 0.8rem;
            -webkit-line-clamp: 2 !important;
        }

        @media only screen and (min-width: 1200px) and (max-width: 1399px) {
            font-size: 0.85rem;
            -webkit-line-clamp: 3 !important;
        }

        @media only screen and (min-width: 1400px) and (max-width: 1599px) {
            font-size: 0.9rem;
            -webkit-line-clamp: 3 !important;
        }

        @media only screen and (min-width: 1600px) and (max-width: 1919px) {
            font-size: 0.9rem;
            -webkit-line-clamp: 3 !important;
        }

        @media only screen and (min-width: 1920px) {
            font-size: 0.9rem;
            -webkit-line-clamp: 3 !important;
        }
    }

    .tag {
        font-size: $font-size-base * .750;

        @include media-breakpoint-only(lg) {
            font-size: $font-size-base * .650;

        }
    }

    .icon {
        width: 18px;
        height: 18px;

        @include media-breakpoint-down(md) {
            width: 20px;
            height: 20px;
        }

        @include media-breakpoint-only(lg) {
            width: 16px;
            height: 16px;
        }
    }
}

.site-detail-map {
    @include media-breakpoint-up(lg) {
        height: 50vh;
    }

    @include media-breakpoint-up(xxl) {
        height: 45vh;
    }

    @include media-breakpoint-down(lg) {
        height: 45vh;
    }
}

.react-multi-carousel-item {
    &:first-child {
        padding-left: 0 !important;
    }

    &:last-child {
        padding-right: 0 !important;
    }
}

.site-slider {

    @include media-breakpoint-up(md) {
        height: 36.5vh;
    }

    @include media-breakpoint-up(lg) {
        height: 62.5vh;
    }

    @include media-breakpoint-up(xl) {
        height: 68vh;
    }

    @include media-breakpoint-only(xl) {
        height: 70vh;
    }

    @media only screen and (min-width: 1920px) {
        height: 62.5vh;
    }

    .list {
        @include media-breakpoint-up(md) {
            width: 155px;
        }

        @include media-breakpoint-up(lg) {
            width: 185px;
        }

        @include media-breakpoint-up(xl) {
            width: 220px;
        }
    }
}

.interestSlideImage {
    width: 100%;
    height: 100%;
    max-height: 65vh;
    position: relative;

    @include media-breakpoint-only(xs) {
        height: 55vh;
    }

    @include media-breakpoint-only(sm) {
        height: 35vh;
    }

    @include media-breakpoint-only(md) {
        height: 115px;
    }

    @include media-breakpoint-only(lg) {
        height: 145px;
    }

    @include media-breakpoint-up(xl) {
        height: 170px;
    }

    @include media-breakpoint-up(xxl) {
        height: 170px;
    }

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }

    .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(35, 35, 35, 0.5);
        z-index: 2;
    }
}

.slider-scroll {
    overflow-y: scroll;

    @include media-breakpoint-up(sm) {
        &::-webkit-scrollbar {
            width: 9px;
            background-color: $gray-200;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
            border-radius: 6px;
            border: 2px solid $gray-200;
        }

        &::-webkit-scrollbar-thumb:active {
            background-color: $gray-800;
        }
    }
}

.preview-site-stamp {
    width: 56px;
    height: 56px;
}

.sites-custom-cards {
    .photo-sm,
    .photo-lg {
        height: 270px !important;
    }

    .bottom-card {
        max-height: 100px !important;
        height: auto !important;
    }
}