.contact-rounded {
    border-radius: 1.25rem !important;
}

.favorite-modal {

    @include media-breakpoint-down(md) {
        border-radius: 0 !important;
    }

    @include media-breakpoint-up(md) {
        border-radius: $tzo-radius;
        min-height: 92.5vh;
    }

    @include media-breakpoint-up(xxl) {
        min-height: 90vh;
    }

    .logo {
        @include media-breakpoint-down(md) {
            margin-top: 5.25rem;
            width: 80%;
        }

        @include media-breakpoint-up(md) {
            margin-top: 4.25rem;
        }

        @include media-breakpoint-up(xxl) {
            margin-top: 4.5rem;
        }
    }

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .content {
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.4);

        @media only screen and (max-width: 320px) {
            padding: 24px 16px !important;
        }
    }

    .social-button {
        background-color: white;
        border: none !important;
        box-shadow: none;
        transition: .225s;
        cursor: pointer;

        span {
            @include media-breakpoint-down(lg) {
                font-size: $font-size-base * 0.925;
            }
        }

        button {
            padding-top: .75rem;
            padding-bottom: .75rem;
        }

        &:hover {
            box-shadow: inset 0 0.125rem 0.25rem 0 rgba(58, 59, 69, .45) !important;
            transition: .225s;
        }

        .facebook {
            color: #1877F2;
        }
    }

    .tzo {
        border: 0 !important;
        background-color: $primary;
        box-shadow: none;
        transition: .225s;
        cursor: pointer;

        button {
            padding-top: .75rem;
            padding-bottom: .75rem;
        }

        &:hover {
            box-shadow: inset 0 0.125rem 0.25rem 0 rgba(58, 59, 69, .325) !important;
            transition: .225s;
        }
    }

    .modal-btn-size {
        @include media-breakpoint-down(md) {
            width: 272px;
        }

        @include media-breakpoint-up(md) {
            width: 290px;
        }
    }

    .close {
        z-index: 2;
        border: 2px solid $white;
    }

    .text-title {
        @include media-breakpoint-down(md) {
            font-size: 28px;
        }

        @include media-breakpoint-up(md) {
            font-size: 34px
        }
    }

    .text-subtitle {
        @include media-breakpoint-down(md) {
            font-size: 32px;
        }

        @include media-breakpoint-up(md) {
            font-size: 36px
        }
    }
}

.facebook-logo {
    width: 24px;
    height: 24px;
    background-image: url('../../../public/img/svg/facebook-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
}


.google-logo {
    width: 22px;
    height: 22px;
    background-image: url('../../../public/img/svg/google.svg');
    background-repeat: no-repeat;
    background-size: contain;
}