.home-card {
    background-color: $light-primary;
    border-color: $light-primary;
}

.content-card {
    // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px -2px 3px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1), 0px -1px 2px 0.25px rgba(0, 0, 0, 0.1);
    width: 100%;

    img {
        user-select: none;
    }

    @include media-breakpoint-down(lg) {

        .top-card {
            height: 30px;
        }

        .bottom-card {
            height: 61px;
        }

        .content-lg {
            width: 100%;
            height: 320px;
            object-fit: cover;
        }

        .content-sm {
            width: 100%;
            height: 160px;
            object-fit: cover;

            .suggest-text {
                font-size: $small-font-size;
            }

            .suggest {
                font-size: 13px;
            }
        }

        .category-sm {
            width: auto;
            width: 21px;
        }

        .category-lg {
            width: auto;
            width: 24px;
        }

        .photo-lg {
            width: 100%;
            height: 320px;
            object-fit: cover;
        }

        .photo-sm {
            width: 100%;
            height: 160px;
            object-fit: cover;

            &.select {
                height: 190px;
            }
        }

        .card-title-sm {
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .suggest-title {
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .fav-container {
            margin-bottom: 0.5rem;
            margin-right: 0.45rem;
            width: 40px;
            height: 40px;

            &.sm {
                width: 36px;
                height: 36px;
            }

            background-color: rgba(255, 255, 255, 0.625);
            transition: 0.225s;

            &.active {
                background-color: rgba(46, 42, 43, 0.625);

                .fav-icon {
                    path {
                        fill: $white;
                        stroke: $white;
                    }
                }
            }

            // &:hover {
            //     .fav-icon {
            //         path {
            //             stroke: $coal;
            //         }
            //     }

            //     background-color: rgba(255, 255, 255, 0.625);
            //     transform: .225s;
            // }
        }

        .fav-icon {
            width: 100%;
            height: 100%;
            transition: .225s;

            path {
                stroke-width: 38px;
                transition: .225s;
            }
        }

        .card-dropdown {
            user-select: none;

            &.close {
                display: none;
            }

            &.open {
                display: flex;
                flex-direction: column;
            }

            .option-sm {
                font-size: 13px;
                transition: .225s;
                cursor: pointer;

                &:hover {
                    background-color: $coal;
                    color: $white;
                    transition: .225s;
                }
            }
        }

        &.card-placeholder {
            .category-lg {
                width: 24px;
                height: 24px;
            }

            .category-sm {
                width: 22px;
                height: 22px;
            }

            .dropdown-lg {
                width: 32px;
                height: 16px;
            }

            .dropdown-sm {
                width: 28px;
                height: 8px;
            }

            .book-mark-lg {
                width: 28px;
                height: 28px;
            }

            .book-mark-sm {
                width: 24px;
                height: 24px;
            }

            .photo-lg {
                width: 100%;
                height: 320px;
                object-fit: cover;
            }

            .photo-sm {
                width: 100%;
                height: 160px;
                object-fit: cover;
            }
        }

        .form-check-input {
            margin: 0;
            width: 1.5em;
            height: 1.5em;
            border: 0px;
            box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
            background-color: black;
            filter: invert(100%);

            &[type="checkbox"] {

                border-radius: 5px !important;
            }

            &:checked {
                // background-color: white;
                border: 0px;
            }
        }

        .stamp {
            top: 4px;
            left: 4px;
            width: 85px;
            height: 85px;
        }
    }

    @include media-breakpoint-up(lg) {

        .top-card {
            height: 34px;
        }

        .bottom-card {
            height: 64px;
        }

        .suggest {

            .content {
                width: 100%;
                height: 394px;
                object-fit: cover;
            }

            .suggest-text {
                font-size: $small-font-size;
            }

            .suggest {
                font-size: 13px;
            }
        }


        .category-sm {
            width: auto;
            width: 24px;
        }

        .category-lg {
            width: auto;
            width: 24px;
        }

        .photo-lg {
            width: 100%;
            height: 360px;

            @include media-breakpoint-only(lg) {
                height: 156px !important;
            }

            object-fit: cover;
        }

        .photo-sm {
            width: 100%;
            height: 360px;
            object-fit: cover;
        }

        .card-title-sm {
            font-size: $font-size-base;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .suggest-title {
            font-size: $font-size-base;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .fav-container {
            margin-bottom: 0.75rem;
            margin-right: 0.75rem;
            width: 38px;
            height: 38px;
            background-color: rgba(255, 255, 255, 0.625);
            transition: .225s;

            .fav-icon {
                path {
                    fill: $dark-green;
                    stroke: $dark-green;
                }
            }

            &.active {
                background-color: rgba(46, 42, 43, 0.625);

                .fav-icon {
                    path {
                        fill: $white;
                        stroke: $white;
                    }
                }
            }
        }

        .fav-icon {
            width: 100%;
            height: 100%;
            transition: .225s;

            path {
                fill: $white;
                stroke-width: 38px;
                stroke: white;
                transition: .225s;
            }
        }

        .card-dropdown {
            user-select: none;

            &.close {
                display: none;
            }

            &.open {
                display: flex;
                flex-direction: column;
            }

            .option-sm {
                transition: .225s;
                cursor: pointer;

                &:hover {
                    background-color: $coal;
                    color: $white;
                    transition: .225s;
                }
            }
        }

        &.card-placeholder {
            .category-lg {
                width: 24px;
                height: 24px;
            }

            .category-sm {
                width: 22px;
                height: 22px;
            }

            .dropdown-lg {
                width: 32px;
                height: 16px;
            }

            .dropdown-sm {
                width: 32px;
                height: 16px;
            }

            .book-mark-lg {
                width: 28px;
                height: 28px;
            }

            .book-mark-sm {
                width: 28px;
                height: 28px;
            }

            .photo-lg {
                width: 100%;
                height: 360px;
                object-fit: cover;
            }

            .photo-sm {
                width: 100%;
                height: 360px;
                object-fit: cover;
            }
        }

        .form-check-input {
            margin: 0;
            width: 1.5em;
            height: 1.5em;
            border: 0px;
            box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
            background-color: black;
            filter: invert(100%);

            &[type="checkbox"] {

                border-radius: 5px !important;
            }

            &:checked {
                // background-color: white;
                border: 0px;
            }
        }

        .stamp {
            width: 25%;
            height: auto;
            top: 6px;
            left: 6px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .suggest {
        .content {

            background-color: $coal;
            color: $white;

            &.sm {
                height: 190px;
            }

            &.lg {
                padding: 1.5rem !important;
            }

            .content-logo {
                max-width: 200px;
                width: 100%;
            }

            .content-text-sm {
                font-size: $font-size-base;
            }

            .content-text-lg {
                font-size: $h5-font-size;
            }
        }

        .suggest-title-sm {
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .suggest-title-lg {
            font-size: $h6-font-size;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

@include media-breakpoint-up(lg) {

    .suggest {
        .content {
            width: 100%;
            height: 394px;
            background-color: $coal;
            color: $white;

            &.sm {
                height: 190px;
            }

            &.lg {
                padding: 1.5rem !important;
            }

            .content-logo {
                max-width: 325px;
                width: 100%;
            }

            .content-text {
                font-size: $h3-font-size;
            }
        }

        .suggest-title {
            font-size: $h6-font-size;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .suggest-text {
        font-size: $small-font-size;
    }

    .suggest {
        font-size: 13px;
    }
}

.fav-icon {
    width: 100%;
    height: 100%;
    transition: .225;

    path {
        fill: $coal;
        stroke-width: 38px;
        transition: .225s;
    }
}

.fav-icon-visual {
    max-height: 24px;
    opacity: 1 !important;

    path {
        fill: none;
        stroke-width: 42px;
        stroke: $coal;
    }

    &.hover {
        transition: .225s;

        path {
            fill: $coal;
        }
    }

    // &:hover.hover {
    //     opacity: 1;
    //     transition: .225s;
    // }

    &.active {
        // opacity: 1 !important;
        transition: .225s;
    }
}

.mobile-sheet {
    flex-direction: column;
    width: 100%;
    height: 0%;
    z-index: 1050;
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    transition: background .625s ease-in-out, height 0s ease-in-out .625s;

    .sheet-container {
        height: calc(100% - $nav-height);
        width: 100%;
        background: $white;
        position: absolute;
        bottom: -100%;
        overflow-y: auto;
        transition: bottom .625s;

        .content {
            height: calc(100% - 59px); // 59px = One line title
        }

        .folder-card {
            overflow: hidden;
            border: 1px solid $light-coal;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background-color: #F9F9F9;
            border-bottom: none;

            .sheet-button {
                min-width: 165px;
            }

            .form-radio {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 0;
                padding-left: 0 !important;
                gap: .25rem;

                .form-check-input[type=radio] {
                    width: 1.3rem;
                    height: 1.3rem;
                    background-color: $black;
                    border-color: white;
                    filter: invert(100%);
                    border: 1px solid $light-coal;
                }

                .form-check-label {
                    margin-left: .25rem;
                    font-family: "norms-bold";
                }
            }

            .folder-select {
                .form-check-input {
                    border: 1px solid $light-coal;

                    &:checked {
                        // background-color: white;
                        border: 1px solid $light-coal !important;
                    }
                }
            }

            .form-check-input {
                margin: 0;
                width: 1.5em;
                height: 1.5em;
                border: 0px;
                box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
                background-color: black;
                filter: invert(100%);

                &[type="checkbox"] {
                    border-radius: 5px !important;
                }

                &:checked {
                    // background-color: white;
                    border: 0px;
                }
            }

            .folder-select {
                background-color: #F9F9F9;
            }
        }

        .head {
            background-size: cover;
            background-position: center;
            overflow: hidden;

            &.content {
                min-height: 175px;
                height: auto !important;

                img {
                    min-width: 100%;
                    min-height: 100%;
                }
            }

            .overlay {
                background-color: rgba(0, 0, 0, 0.5);
                height: 100% !important;
                z-index: 1;
            }
        }

        .form-control-color {
            width: 42px;
        }

        .rc {
            &-slider {
                height: 2px;
            }

            &-slider-rail {
                background-color: #D9D9D9 !important;
                height: 2px;
            }

            &-slider-track {
                background-color: #5A5C69;
                height: 2px;
            }

            &-slider-handle,
            &-slider-dragging {
                border-color: #5A5C69 !important;
                border-width: 2px;
                background-color: $white;
                opacity: 1;
                margin-top: -6px;

                &:active {
                    box-shadow: 0 0 5px $primary;
                }

                &-dragging {
                    box-shadow: 0 0 0 5px $primary !important;
                }

                &-1 {
                    transform: translateX(0%) !important;
                }

                &-2 {
                    transform: translateX(-50%) !important;
                }
            }
        }
    }

    .header {
        border-bottom: 1px solid $light-coal;

        &.sticky {
            position: sticky;
            top: 0;
            background-color: white;
            z-index: 20;
        }

        .control {
            z-index: 5;
        }
    }

    &.active {

        opacity: 1;
        height: 100vh;
        height: 100dvh;
        background: rgba(0, 0, 0, 0.8);
        transition: background .625s;

        .sheet-container {
            position: absolute;
            bottom: 0;
            height: calc(100% - $mobile-topbar);
            width: 100%;
            // border-radius: 5px;
            background-color: $white;
            transition: bottom .625s;
            overflow-y: auto;

            // .header {
            //     border-bottom: 1px solid $light-coal;

            //     &.sticky {
            //         position: sticky;
            //         top: 0;
            //         background-color: white;
            //         z-index: 10;
            //     }

            //     .control {
            //         z-index: 1;
            //     }
            // }
        }

        .option {
            box-shadow: none;
            transition: .225s;
        }

        @include media-breakpoint-up(lg) {
            .option:hover {
                box-shadow: inset 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
                transition: .225s;
            }
        }

        .select-card {
            @include media-breakpoint-down(lg) {
                height: 170px;
            }

            @include media-breakpoint-up(lg) {
                height: 360px;
            }
        }
    }
}

.desktop-folder-card {
    border: 1px solid $light-coal;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #F9F9F9;
    border-bottom: none;

    .sheet-button {
        min-width: 165px;
    }

    .form-radio {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        padding-left: 0 !important;
        gap: .25rem;

        .form-check-input[type=radio] {
            width: 1.3rem;
            height: 1.3rem;
            background-color: $black;
            border-color: white;
            filter: invert(100%);
            border: 1px solid $light-coal;
        }

        .form-check-label {
            margin-left: .25rem;
            font-family: "norms-bold";
        }
    }

    .folder-select {
        .form-check-input {
            border: 1px solid $light-coal;

            &:checked {
                // background-color: white;
                border: 1px solid $light-coal !important;
            }
        }
    }

    .form-check-input {
        margin: 0;
        width: 1.5em;
        height: 1.5em;
        border: 0px;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
        background-color: black;
        filter: invert(100%);

        &[type="checkbox"] {
            border-radius: 5px !important;
        }

        &:checked {
            // background-color: white;
            border: 0px;
        }
    }

    .folder-select {
        background-color: #F9F9F9;
    }

    .head {
        background-size: cover;
        background-position: center;
        overflow: hidden;

        &.content {
            min-height: 215px;
            height: auto !important;

            img {
                width: 100%;
                min-height: 100%;
            }
        }

        .overlay {
            background-color: rgba(0, 0, 0, 0.5);
            height: 100% !important;
            z-index: 1;
        }
    }

    ::-webkit-scrollbar {
        width: 4px;
        background-color: none;
        border-top-right-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $light-coal;
        border-radius: 6px;
        // border: 2px solid $gray-200;
    }

    ::-webkit-scrollbar-thumb:active {
        background-color: $gray-700;
    }
}

.content-list {
    .content-card {
        .photo-lg {
            width: 100%;
            height: 290px !important;
            object-fit: cover;
        }

        .photo-sm {
            width: 100%;
            height: 290px !important;
            object-fit: cover;
        }

        .bottom-card {
            height: auto !important;
        }
    }
}