.guide-hero {
    img {
        @include media-breakpoint-up(lg) {
            height: 625px;
            width: 100%;
        }

        @include media-breakpoint-down(lg) {
            height: 100vw;
            width: 100%;
        }
    }
}

.must-container {
    .title-must {

        @include media-breakpoint-up(lg) {
            font-size: $h1-font-size * 2 !important;
        }

        @include media-breakpoint-down(lg) {
            font-size: $h1-font-size;
        }
    }

    .subTitle {

        @include media-breakpoint-up(lg) {
            font-size: $h3-font-size;
        }

        @include media-breakpoint-down(lg) {
            font-size: $h5-font-size;
        }
    }

    .featured-card {
        @include media-breakpoint-up(lg) {
            border: 1px solid #cecbbe;
        }
    }
}

.banner-must {
    @include media-breakpoint-down(lg) {
        height: 50vh;
    }

    @include media-breakpoint-up(lg) {
        height: 42.5vh;
    }

    button {
        bottom: 12.5%;
        border: 1px solid white;
        background-color: rgba(255, 255, 255, 0.25);
        opacity: 0;
        transition: .225s;
    }

    &:hover {
        button {
            opacity: 1;
            transition: .225s;
        }
    }

    .content {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .banner-overlay {
            width: 100%;
            height: 100%;
            background-color: rgb(0, 0, 0, 0.40);
        }

        @include media-breakpoint-up(lg) {
            border-radius: 5px !important;

            .title {
                font-size: $h2-font-size !important;
            }

            .subtitle {
                font-size: $h5-font-size !important;
            }
        }
    }
}

.header-must {
    width: 100%;

    .title {
        @include media-breakpoint-down(md) {
            font-size: $h1-font-size * 2.05;
            line-height: 1.2;
        }

        @include media-breakpoint-up(md) {
            font-size: $h1-font-size * 1.75;

        }

        @include media-breakpoint-up(lg) {
            font-size: $h1-font-size * 1.65;
        }

        @include media-breakpoint-up(xl) {
            font-size: $h1-font-size * 2.25;
        }

        @include media-breakpoint-up(xxl) {
            font-size: $h1-font-size * 2.15;
        }

        @media only screen and (min-width: 1920px) {
            font-size: $h1-font-size * 2.85;
        }
    }

    .subTitle {
        @include media-breakpoint-down(md) {
            font-size: $font-size-base;
            font-family: "norms-bolder";
            text-align: center;
        }

        @include media-breakpoint-up(md) {
            font-size: $h3-font-size * .865;
            font-family: "norms-bold";
        }

        @include media-breakpoint-up(lg) {
            font-size: $h3-font-size * .9;
        }

        @include media-breakpoint-up(xl) {
            font-size: $h3-font-size * 1.15;
        }

        @include media-breakpoint-up(xxl) {
            font-size: $h3-font-size * 1;
        }

        @media only screen and (min-width: 1920px) {
            font-size: $h3-font-size * 1.25;
        }
    }

    .overlay {
        @include media-breakpoint-down(md) {
            background-color: rgba(0, 0, 0, 0.35);
        }
    }

    .head-image {
        @include media-breakpoint-up(lg) {
            width: 100%;
            height: 100%;
            max-height: 55vh;
            object-fit: cover;
            object-position: center;
        }

        @include media-breakpoint-up(xl) {
            width: 100%;
            height: 100%;
            max-height: 65vh;
            object-fit: cover;
            object-position: center;
        }

        @include media-breakpoint-up(xxl) {
            width: 100%;
            // height: 100%;
            height: 67.5vh;
            object-fit: cover;
            object-position: center;
        }
    }

    .slide-image {
        user-select: none;

        @include media-breakpoint-down(lg) {
            width: 100%;
            height: 60vh;
            object-fit: cover;
            object-position: center;
        }

        @include media-breakpoint-up(lg) {
            width: 100%;
            height: 75vh;
            object-fit: cover;
            object-position: center;
        }

        @include media-breakpoint-up(xl) {
            width: 100%;
            height: 65vh;
            object-fit: cover;
            object-position: center;
        }

        @include media-breakpoint-up(xxl) {
            width: 100%;
            height: 67.5vh;
            object-fit: cover;
            object-position: center;
        }
    }

    .review-title {
        @include media-breakpoint-up(lg) {
            font-size: $h2-font-size;
        }

        @include media-breakpoint-down(lg) {
            font-size: $h5-font-size;
        }
    }

    @include media-breakpoint-up(md) {


        .slider-shadow::after {
            box-shadow: inset 65px 0px 37px -67px #000000;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .react-multi-carousel-track {
        height: 100% !important;
    }
}

.header-must-background {
    @include media-breakpoint-down(md) {
        background: url('../../../public/img/bestof-home.png');
        background-size: cover;
        background-position: center;
        color: $white;
    }
}

.must-filter {
    z-index: 1005;
    background-color: $white;

    .form-check {
        margin-bottom: 0;
    }

    .form-check-input {

        @include media-breakpoint-up(md) {
            width: 1.075rem;
            height: 1.075rem;
        }

        @include media-breakpoint-up(lg) {
            width: 1.15rem;
            height: 1.15rem;
        }

        @include media-breakpoint-up(xl) {
            width: 1.10rem;
            height: 1.10rem;
        }

        @include media-breakpoint-up(xxl) {
            width: 1.10rem;
            height: 1.10rem;
        }

        margin-right: 6px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .form-check-label {
        white-space: nowrap;

        @include media-breakpoint-up(md) {
            font-size: $h5-font-size * .815;
        }

        @include media-breakpoint-up(lg) {
            font-size: $h5-font-size * .875;
        }

        @include media-breakpoint-up(xl) {
            font-size: $h5-font-size * 0.825;
        }

        @include media-breakpoint-up(xxl) {
            font-size: $h5-font-size * 0.925;
        }

        user-select: none;
        -webkit-user-select: none;
    }
}

.must-card-container {
    .header {
        object-fit: cover;
        object-position: center;
        width: 100%;

        @include media-breakpoint-down(md) {
            height: 285px;
            max-height: 285px;
            -webkit-line-clamp: 5 !important;
        }

        @include media-breakpoint-only(md) {
            height: 235px;
            max-height: 235px;
        }

        @include media-breakpoint-only(lg) {
            height: 310px;
            max-height: 310px;
        }

        @include media-breakpoint-only(xl) {
            height: 215px;
            -webkit-line-clamp: 4 !important;
        }

        @include media-breakpoint-up(xxl) {
            height: 255px;
            max-height: 255px;
        }

        @media only screen and (min-width: 1920px) {
            height: 285px;
            max-height: 285px;
        }
    }
}

.must-card {
    a:hover {
        color: $black !important;
    }

    @include media-breakpoint-down(md) {
        height: 536px !important;
    }

    .header {
        object-fit: cover;
        object-position: center;
        width: 100%;

        @include media-breakpoint-down(md) {
            height: 285px;
            max-height: 285px;
            -webkit-line-clamp: 5 !important;
        }

        @include media-breakpoint-only(md) {
            height: 235px;
            max-height: 235px;
        }

        @include media-breakpoint-only(lg) {
            height: 310px;
            max-height: 310px;
        }

        @include media-breakpoint-only(xl) {
            height: 215px;
            -webkit-line-clamp: 4 !important;
        }

        @include media-breakpoint-up(xxl) {
            height: 255px;
            max-height: 255px;
        }

        @media only screen and (min-width: 1920px) {
            height: 285px;
            max-height: 285px;
        }
    }

    .logo-category {
        width: 32px;
        height: 32px;

        img {
            min-width: 100%;
            filter: invert(45%);
        }
    }

    .title {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media only screen and (min-width: 1920px) {
            // font-size: 1.4rem;
            -webkit-line-clamp: 3 !important;
        }

        @include media-breakpoint-up(xxl) {
            // font-size: 1.3rem;
            -webkit-line-clamp: 2 !important;
        }

        @include media-breakpoint-only(xl) {
            // font-size: 1.25rem;
            -webkit-line-clamp: 4 !important;
        }

        @include media-breakpoint-only(lg) {
            // font-size: 1.4rem;
            -webkit-line-clamp: 2 !important;
        }

        @include media-breakpoint-only(md) {
            // font-size: 1.125rem;
            -webkit-line-clamp: 3 !important;
        }

        @include media-breakpoint-down(md) {
            // font-size: 1.45rem;
            -webkit-line-clamp: 2 !important;
        }
    }

    .description {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media only screen and (min-width: 1920px) {
            font-size: 1rem;
            -webkit-line-clamp: 4 !important;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 0.9rem;
            -webkit-line-clamp: 4 !important;
        }

        @include media-breakpoint-only(xl) {
            font-size: 0.9rem;
            -webkit-line-clamp: 4 !important;
        }

        @include media-breakpoint-only(lg) {
            font-size: 0.9rem;
            -webkit-line-clamp: 4 !important;
        }

        @include media-breakpoint-only(md) {
            font-size: 0.825rem;
            -webkit-line-clamp: 4 !important;
        }

        @include media-breakpoint-down(md) {
            font-size: 0.925rem;
            line-height: 1.25;
            -webkit-line-clamp: 3 !important;
        }
    }
}

.sponsor-banner {

    img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
        position: center;

    }

    video {

        width: 100%;
        height: 100%;
        object-fit: cover;

        @include media-breakpoint-only(sm) {
            object-position: 0% 85%
        }

        @include media-breakpoint-only(md) {
            object-position: 0% 85%
        }

        @include media-breakpoint-only(lg) {
            object-position: 0% 85%
        }

        @include media-breakpoint-only(xl) {
            object-position: 0% 82.5%
        }

        @include media-breakpoint-up(xxl) {
            object-position: 0% 78.5%
        }

        @media only screen and (min-width: 1920px) {
            object-position: 0% 82.5%
        }
    }

    .title {
        font-size: $h1-font-size * 1.35;
    }

    @include media-breakpoint-only(md) {
        max-height: 45vh;
        height: 45vh;

        .title {
            font-size: $h1-font-size * 1.125;
        }
    }

    @include media-breakpoint-up(lg) {
        max-height: 62.5vh;
        height: 62.5vh;

        .title {
            font-size: $h1-font-size * 1.75;
        }
    }

    button {
        bottom: 12.5%;
        border: 1px solid white;
        background-color: rgba(255, 255, 255, 0.25);
        opacity: 0;
        transition: .225s;
    }

    &:hover {
        button {
            opacity: 1;
            transition: .225s;
        }
    }
}

.horizontal-scroll {
    overflow-x: scroll;

    @include media-breakpoint-up(xl) {
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.must-slide-container {
    .card {
        @include media-breakpoint-down(lg) {
            height: 100% !important;
            max-height: 565px;
        }
    }

    @include media-breakpoint-down(md) {
        overflow-x: auto;

        img {
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            transition: .15s;
        }

        .popover-home-card {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.65);
            transition: .15s;
            height: 100% !important;
        }
    }


    @include media-breakpoint-up(sm) {
        img {

            width: 100%;
            object-fit: cover;
            transition: .15s;


            @include media-breakpoint-up(xl) {
                max-height: 56.5vh;
            }

            @include media-breakpoint-up(xxl) {
                max-height: 65vh;
            }
        }

        .popover-home-card {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.65);
            opacity: 0;
            transition: .15s;

            @include media-breakpoint-only(sm) {
                .title-1 {
                    font-size: $h6-font-size !important;
                }

                .title-2 {
                    font-size: $h6-font-size * 0.9 !important;
                }

                .features {
                    font-size: $h6-font-size * 0.75 !important;

                    button {
                        width: 100%;
                    }
                }

                .coming {
                    font-size: $h4-font-size;
                }
            }

            @include media-breakpoint-only(md) {

                .title-1 {
                    font-size: $h5-font-size !important;
                }

                .title-2 {
                    font-size: $h6-font-size !important;
                }

                .features {
                    font-size: $h6-font-size * 0.95 !important;

                    button {
                        width: 100%;
                    }
                }

                .coming {
                    font-size: $h4-font-size !important;
                }
            }

            @include media-breakpoint-up(lg) {
                .title-1 {
                    font-size: $h4-font-size !important;
                }

                .title-2 {
                    font-size: $h5-font-size !important;
                }

                .features {
                    font-size: $h6-font-size !important;
                }

                .coming {
                    font-size: $h4-font-size !important;
                }
            }

            @include media-breakpoint-up(xxl) {
                .title-1 {
                    font-size: $h2-font-size !important;
                }

                .title-2 {
                    font-size: $h4-font-size !important;
                }

                .features {
                    font-size: $h5-font-size !important;
                }
            }

            @media only screen and (min-width: 1400px) and (max-width: 1919px) {
                .title-1 {
                    font-size: $h3-font-size !important;
                }

                .title-2 {
                    font-size: $h5-font-size !important;
                }

                .features {
                    font-size: $h6-font-size !important;
                }
            }

            @media only screen and (min-width: 1920px) {
                .title-1 {
                    font-size: $h2-font-size !important;
                }

                .title-2 {
                    font-size: $h4-font-size !important;
                }

                .features {
                    font-size: $h5-font-size !important;
                }
            }
        }
    }

    .card-video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0% 100%;

        @include media-breakpoint-down(lg) {
            max-height: 565px;
        }
    }
}

.check-slider {



    .horizontal-scroll {
        overflow-x: unset;
    }

    #left {
        left: -20px !important;
        cursor: pointer;
        width: 18px !important;
    }

    #right {
        right: -60px !important;
        cursor: pointer;
        width: 18px !important;
    }
}

.top-progress {
    height: 100%;

    button {
        z-index: 1;
        width: 40px;
        height: 40px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .vl {
        display: block;
        width: 1.85px;
        background-color: #2E2A2B;
        height: 98%; // Avoid overflow vertical line
    }

    .control {
        background-color: white;
        border-color: #2E2A2B;
        transition: .125s;

        svg {
            color: #2E2A2B;
            transition: .125s;
        }

        &:hover {
            background-color: #2E2A2B;
            transition: .125s;

            svg {
                color: white;
                transition: .125s;
            }
        }
    }

    .item {
        width: 16px;
        height: 16px;
        background-color: #2E2A2B;
        color: white;
        padding: 0;
        transition: .125s;

        span {
            opacity: 0;
            transition: .125s;

        }

        &:hover {
            width: 40px;
            height: 40px;
            transition: .125s;

            span {
                opacity: 1;
                transition: .125s;
            }
        }
    }

    .active {
        width: 40px;
        height: 40px;
        background-color: #2E2A2B;
        color: white;
        transition: .125s;
    }

    .control-disabled {
        background-color: white;
        border-color: rgba(46, 42, 43, 0.5);
        cursor: default;
        transition: .125s;

        svg {
            color: rgba(46, 42, 43, 0.5);
            transition: .125s;
        }
    }
}

.list-progress {

    .vl {
        display: block;
        width: 1.85px;
        background-color: #2E2A2B;
        height: 98%; // Avoid overflow vertical line
    }

    .control {
        z-index: 1;
        width: 36px;
        height: 36px;
        background-color: white;
        border-color: #2E2A2B;
        transition: .125s;

        @include media-breakpoint-down(md) {
            width: 32px;
            height: 32px;
            padding: 6px;
        }

        svg {
            width: 100%;
            height: 100%;
            color: #2E2A2B;
            transition: .125s;
        }

        &:hover {
            background-color: #2E2A2B;
            transition: .125s;

            svg {
                color: white;
                transition: .125s;
            }
        }
    }

    .badage-control {

        background-color: #ebebeb;
        transition: .225s;

        @include media-breakpoint-down(md) {
            width: 45px;
            height: 8px;
        }

        @include media-breakpoint-up(md) {
            width: 45px;
            height: 8px;
        }

        &:hover {
            background-color: #ccc;
            transition: .225s;
        }

    }

    .badage-control-active {
        @include media-breakpoint-down(md) {
            width: 45px;
            height: 8px;
        }

        @include media-breakpoint-up(md) {
            width: 45px;
            height: 8px;
        }

        transition: .225s;
        background-color: #ccc;
    }

    .slide-image {
        @include media-breakpoint-down(lg) {
            width: 100%;
            height: 55vh;
            object-fit: cover;
            object-position: center;
        }
    }
}

.header-progress {
    .progress-head {
        width: 100%;
        height: 100%;
        height: 67.5vh;
        object-fit: cover;
        object-position: center;
    }

    .slide-image {
        @include media-breakpoint-down(lg) {
            width: 100%;
            height: 55vh;
            object-fit: cover;
            object-position: center;
        }
    }
}

.navigation-cards {
    @include media-breakpoint-only(md) {
        height: 47.5vh;
        max-height: 425px;
    }

    @include media-breakpoint-only(lg) {
        height: 65vh;
    }

    @include media-breakpoint-up(xl) {
        height: 72.5vh;
    }

    @include media-breakpoint-up(xxl) {
        height: 75vh;
    }

    @media only screen and (min-width: 1920px) {
        height: 75vh;
    }

    .card {
        @include media-breakpoint-down(sm) {
            height: 100% !important;
        }
    }

    @include media-breakpoint-down(md) {
        overflow-x: auto;

        img {
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            transition: .15s;
        }

        .popover-home-card {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.65);
            transition: .15s;
            height: 100% !important;
        }
    }


    @include media-breakpoint-up(sm) {
        img {

            width: 100%;
            object-fit: cover;
            // transition: .15s;

            @include media-breakpoint-only(md) {
                height: 47.5vh;
                max-height: 425px;
            }

            @include media-breakpoint-only(lg) {
                height: 65vh;
            }

            @include media-breakpoint-up(xl) {
                height: 72.5vh;
            }

            @include media-breakpoint-up(xxl) {
                height: 75vh;
            }

            @media only screen and (min-width: 1920px) {
                height: 75vh;
            }
        }

        .sponsor-image {
            object-fit: fill !important;
        }

        // img:hover {
        //     transition: .15s;
        //     transform: scale(1.025);
        // }

        .popover-home-card {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.65);
            transition: .15s;

            h2 {
                line-height: 1.5 !important;
            }

            @include media-breakpoint-only(sm) {
                .title-1 {
                    font-size: $h6-font-size !important;
                }

                .title-2 {
                    font-size: $h6-font-size * 0.9 !important;
                }

                .features {
                    font-size: $h6-font-size * 0.75 !important;

                    button {
                        width: 100%;
                    }
                }

                .coming {
                    font-size: $h4-font-size;
                }
            }

            @include media-breakpoint-only(md) {

                .title-1 {
                    font-size: $h5-font-size !important;
                }

                .title-2 {
                    font-size: $h6-font-size !important;
                }

                .features {
                    font-size: $h6-font-size * 0.95 !important;

                    button {
                        width: 100%;
                    }
                }

                .coming {
                    font-size: $h4-font-size !important;
                }
            }

            @include media-breakpoint-up(lg) {
                .title-1 {
                    font-size: $h4-font-size !important;
                }

                .title-2 {
                    font-size: $h5-font-size !important;
                }

                .features {
                    font-size: $h6-font-size !important;
                }

                .coming {
                    font-size: $h4-font-size !important;
                }
            }

            @include media-breakpoint-up(xxl) {
                .title-1 {
                    font-size: $h2-font-size !important;
                }

                .title-2 {
                    font-size: $h4-font-size !important;
                }

                .features {
                    font-size: $h5-font-size !important;
                }
            }

            @media only screen and (min-width: 1400px) and (max-width: 1919px) {
                .title-1 {
                    font-size: $h3-font-size !important;
                }

                .title-2 {
                    font-size: $h5-font-size !important;
                }

                .features {
                    font-size: $h6-font-size !important;
                }
            }

            @media only screen and (min-width: 1920px) {
                .title-1 {
                    font-size: $h2-font-size !important;
                }

                .title-2 {
                    font-size: $h4-font-size !important;
                }

                .features {
                    font-size: $h5-font-size !important;
                }
            }
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 0% 100%;

            @include media-breakpoint-only(md) {
                height: 47.5vh;
                max-height: 425px;
            }

            @include media-breakpoint-only(lg) {
                height: 65vh;
            }

            @include media-breakpoint-up(xl) {
                height: 72.5vh;
            }

            @include media-breakpoint-up(xxl) {
                height: 75vh;
            }

            @media only screen and (min-width: 1920px) {
                height: 75vh;
            }
        }
    }

    .react-multi-carousel-item--active {
        transition: .4s;
        transform: scale(1) !important;
    }

    .react-multi-carousel-item {
        transition: .4s;
        transform: scale(0.935);
    }
}


.header-miniguide {
    @include media-breakpoint-down(lg) {
        position: relative;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
        z-index: 1;
    }

    @include media-breakpoint-up(lg) {
        h1 {
            font-size: $h2-font-size !important;
        }
    }
}

.miniguide-content {
    overflow: hidden;

    .title {
        @include media-breakpoint-up(lg) {
            border-bottom: 1px solid white
        }
    }

    .swiper-control {
        &.left {
            position: absolute;
            transform: translate(-50%, -50%) !important;
            top: 50% !important;
            left: -28px;
        }

        &.right {
            position: absolute;
            transform: translate(-50%, -50%) !important;
            top: 50% !important;
            right: -70px;
        }
    }

    .slider-card {

        img, .content-picture {
            @include media-breakpoint-up(lg) {
                height: 285px;
            }

            @include media-breakpoint-down(lg) {
                height: 75vw;
            }

            object-fit: cover;
        }

        &:hover {
            .new-link-white::before {
                width: 0%;
                transition: 0.225s;
            }
        }

        .ranking {
            position: absolute;
            top: -15px;
            left: -15px;
            border-radius: 50%;
            background-color: $dark-brown;
            width: 85px;
            height: 85px;
            z-index: 5;

            span {
                margin-left: 8px;
                margin-top: 4px;
            }
        }
    }

    .swiper {
        .swiper-wrapper {
            position: relative;
        }

        .swiper-button-next {
            width: 40px;
            top: 0%;
            right: 0;
            height: 100%;
            margin-top: 0;
            background-color: #00000000 !important;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 25%, rgba(0, 0, 0, 0.75) 100%);

            &::after {
                color: white;
                font-size: 18px !important;
                font-weight: bold;
                margin-left: 8px;
            }
        }

        .swiper-button-prev {
            width: 40px;
            top: 0%;
            left: 0;
            height: 100%;
            margin-top: 0;
            background-color: #00000000 !important;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.35) 15%, rgba(0, 0, 0, 0.75) 100%);

            &::after {
                color: white;
                font-size: 18px !important;
                font-weight: bold;
                margin-right: 8px;
            }
        }

        .swiper-button-disabled {
            display: none;
        }
    }
}

.must-filter {

    .react-horizontal-scrolling-menu--item {
        display: flex;
    }

    .react-horizontal-scrolling-menu--arrow-right {
        height: 100%;
        background-color: white;
        height: 32px;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .horizontal-right {
        height: 100%;
        padding: .75rem;

        svg {
            color: $coal !important;
            width: 16px;

            @include media-breakpoint-down(md) {
                width: 12px;
            }
        }
    }

    .react-horizontal-scrolling-menu--arrow-left {
        height: 100%;
        background-color: white;
        height: 32px;


        @include media-breakpoint-down(lg) {
            height: 32px;
            display: none;
        }
    }

    .horizontal-left {
        height: 100%;
        padding: .75rem;

        svg {
            color: $coal !important;
            width: 16px;

            @include media-breakpoint-down(md) {
                width: 12px;
                display: none;
            }
        }
    }

    .react-horizontal-scrolling-menu--separator {
        margin-right: .5rem !important;
    }

    .cat-filter {
        @include media-breakpoint-down(lg) {
            min-width: 35vw;
        }

        @include media-breakpoint-up(lg) {
            min-width: 130px;
        }

        box-shadow: none;
        transition: .225s;
        background-color: inherit;
        color: $coal;

        @include media-breakpoint-up(lg) {
            &:hover {
                transition: .225s;
                color: $white;
                background-color: $coal;
            }
        }

        &.active {
            transition: .225s;
            color: $white;
            background-color: $coal;
        }
    }
}

.promotion-banner {
    // height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: bottom;

    @include media-breakpoint-up(lg) {
        border-radius: 5px;
        -webkit-transform: scale(1.025);
        transform: scale(1.025);
        height: 425px;
    }

    @include media-breakpoint-down(lg) {
        height: 35vh;
    }
}