html {
  position: relative;
  min-height: 100%;
  scroll-behavior: auto !important;
}

body {
  height: 100%;

  @include media-breakpoint-up(lg) {
    padding-top: $nav-height;
  }

  @include media-breakpoint-down(lg) {
    padding-top: $mobile-topbar;
    padding-bottom: $mobile-nav-height;
  }

  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

a {
  &:focus {
    outline: none;
  }
}

p {
  font-family: "inter-regular";
}

hr {
  @include media-breakpoint-down(lg) {
    opacity: 0.1;
  }
}

@font-face {
  font-family: "norms-bold";
  src: url('../../../public/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: "norms";
  src: url('../../../public/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "norms-bolder";
  src: url('../../../public/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "norms-thin";
  src: url('../../../public/fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: "norms-semi-bolder";
  src: url('../../../public/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: "Joane-regular";
  src: url('../../../public/fonts/Joane-Light.woff') format('woff');
}

@font-face {
  font-family: "Joane-bold";
  src: url('../../../public/fonts/Joane-Regular.woff') format('woff');
}

@font-face {
  font-family: "Joane-bolder";
  src: url('../../../public/fonts/Joane-Bold.woff') format('woff');
}

@font-face {
  font-family: "Joane-thin";
  src: url('../../../public/fonts/Joane-Thin.woff') format('woff');
}

@font-face {
  font-family: "Joane-Light";
  src: url('../../../public/fonts/Joane-Light.woff') format('woff');
}

@font-face {
  font-family: "inter-extra-light";
  src: url('../../../public/fonts/Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: "inter-light";
  src: url('../../../public/fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: "inter-regular";
  src: url('../../../public/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "inter-medium";
  src: url('../../../public/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: "inter-semi-bold";
  src: url('../../../public/fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: "inter-bold";
  src: url('../../../public/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "inter-extra-bold";
  src: url('../../../public/fonts/Inter-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: "inter-black";
  src: url('../../../public/fonts/Inter-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'fitzgerald-italic';
  src: url('../../../public/fonts/HVFitzgerald-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'fitzgerald-bold-italic';
  src: url('../../../public/fonts/HVFitzgerald-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'fitzgerald-regular';
  src: url('../../../public/fonts/HVFitzgerald-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'fitzgerald-bold';
  src: url('../../../public/fonts/HVFitzgerald-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.inter {
  &-extra-light {
    font-family: "inter-extra-light";
  }

  &-light {
    font-family: "inter-light";
  }

  &-regular {
    font-family: "inter-regular";
  }

  &-medium {
    font-family: "inter-medium";
  }

  &-semi-bold {
    font-family: "inter-semi-bold";
  }

  &-bold {
    font-family: "inter-bold";
  }

  &-extra-bold {
    font-family: "inter-extra-bold";
  }

  &-black {
    font-family: "inter-black";
  }
}

.fitzgerald {
  &-regular {
    font-family: "fitzgerald-regular";
  }

  &-bold {
    font-family: "fitzgerald-bold";
  }

  &-italic {
    font-family: "fitzgerald-italic";
  }

  &-bold-italic {
    font-family: "fitzgerald-bold-italic";
  }
}

.tt-norms {
  font-family: "norms";
  font-weight: 500;
  z-index: 1;
}

.tt-norms-regular {
  font-family: "norms-regular";
  font-weight: 500;
  z-index: 1;
}

.tt-norms-bold {
  font-family: "norms-bold";
  font-weight: 500;
  z-index: 1;
}

.tt-norms-semi-bolder {
  font-family: "norms-semi-bolder";
  font-weight: 500;
  z-index: 1;
}

.tt-norms-bolder {
  font-family: "norms-bolder";
  font-weight: 500;
  z-index: 1;
}

.tt-norms-thin {
  font-family: "norms-thin";
  z-index: 1;
}

.joane {
  font-family: "Joane-regular";
  font-weight: 300;
  z-index: 1;
}

.joane-light {
  font-family: "Joane-Light";
  z-index: 1;
}

.joane-bold {
  font-family: "Joane-bold";
  font-weight: 300;
  z-index: 1;
}

.joane-bolder {
  font-family: "Joane-bolder";
  font-weight: 300;
  z-index: 1;
}

.tt-norms-title {
  @media only screen and (max-width: 677px) {
    font-family: "norms-bold";
    font-weight: 500;
    font-size: 40px;
  }

  @media only screen and (min-width: 678px) {
    font-family: "norms-bold";
    font-weight: 500;
    font-size: 56px;
  }
}

.joane-title {
  @media only screen and (max-width: 677px) {
    font-family: "Joane-regular";
    font-weight: 200;
    font-size: 40px;
  }

  @media only screen and (min-width: 678px) {
    font-family: "Joane-regular";
    font-weight: 200;
    font-size: 56px;
  }
}

.text-light-gray {
  color: #C1C0C0;
}

.fixedNav {
  z-index: $zindex-fixed;
}

// Set container padding to match gutter width instead of default 15px
.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

.svg-inline--fa {
  height: auto;
  color: $gray-700;
}

.vh-100-sticky-nav {
  @media only screen and (min-width: 992px) {
    height: calc(100vh - $nav-height);
  }
}

.icon-dark {
  color: $dark;
}

.cursor-pointer {
  cursor: pointer;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.select-default {
  -webkit-appearance: none;
  appearance: none;
}

.instagram-widget {
  .eapps-instagram-feed-title-container {
    display: none !important;
  }
}

.text-coal {
  color: $coal !important;
}

.bg-coal {
  background-color: $coal;
}

.bg-light-coal {
  background-color: $light-coal;
}

.bg-sea {
  background-color: $sea;
}

.bg-laurel {
  background-color: $laurel;
}

.bg-dark-green {
  background-color: $dark-green;
}

.bg-brown {
  background-color: $brown;
}

.bg-beige {
  background-color: $beige;
}

.bg-dark-brown {
  background-color: $dark-brown;
}

.text-sea {
  color: $sea;
}

.text-light-coal {
  color: $light-coal;
}

.text-muted-light-coal {
  color: #878787;
}

.text-muted-coal {
  color: #625F60;
}

.text-facebook {
  color: #1877F2 !important;
}

.text-laurel {
  color: $laurel !important
}

.text-dark-green {
  color: $dark-green !important
}

.text-brown {
  color: $brown !important
}

.text-beige {
  color: $beige !important
}

.text-dark-brown {
  color: $dark-brown !important
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

.fs-title {
  @include media-breakpoint-up(lg) {
    font-size: 3.85rem;
  }

  @include media-breakpoint-down(lg) {
    font-size: 2.65rem;
  }
}

.bg-facebook {
  background-color: #1877F2 !important;
}

.bg-light-coal {
  background-color: $light-coal;
}

.container-size {
  @media only screen and (min-width: 1920px) {
    max-width: 1536px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  @include media-breakpoint-up(md) {
    .photo-lg {
      width: 100%;

      @media only screen and (min-width: 1366px) and (max-width: 1536px) {
        height: 265px !important;
      }

      @include media-breakpoint-between(md, xl) {
        height: 190px !important;
      }

      object-fit: cover;
    }

    .photo-sm {
      width: 100%;

      @media only screen and (min-width: 1366px) and (max-width: 1536px) {
        height: 265px !important;
      }

      @include media-breakpoint-between(md, xl) {
        height: 190px !important;
      }


      object-fit: cover;
    }
  }
}

.invert-color {
  filter: invert(100%);
}

.radius {
  border-radius: 5px;
}

.radius-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.radius-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.fit-cover {
  object-fit: cover;
}

.radius-bottom {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.space-nowrap {
  white-space: nowrap !important;
}

.clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-shadow {
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1), 0px -1px 2px 0.25px rgba(0, 0, 0, 0.1);
}

.opacity-85 {
  opacity: .85;
}

.lh-md {
  line-height: 1.8;
}

.lh-md-lg {
  line-height: 1.75;
}

.flex-basis-0 {
  flex-basis: 0;
}

.border-radius-top-left-tzo {
  border-top-left-radius: $tzo-radius;
}

.border-radius-top-right-tzo {
  border-top-right-radius: $tzo-radius;
}

.border-radius-bottom-left-tzo {
  border-bottom-left-radius: $tzo-radius;
}

.border-radius-bottom-right-tzo {
  border-bottom-right-radius: $tzo-radius;
}

.fit-cover {
  object-fit: cover;
}

.tzo-title {
  @include media-breakpoint-down(lg) {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  @include media-breakpoint-up(lg) {
    font-size: $h4-font-size;
  }
}

.tzo-subtitle {
  @include media-breakpoint-down(lg) {
    font-size: 1rem !important;
  }

  @include media-breakpoint-up(lg) {
    font-size: $h5-font-size;
  }
}

.border-coal-semi-transparent {
  border-color: rgba(46, 41, 42, 0.35) !important;
}

.z-index-1 {
  z-index: 1;
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
    border-top-right-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light-coal;
    border-radius: 6px;
    // border: 2px solid $gray-200;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: $gray-700;
  }
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.touch-sheet {
  .villa-slider {
    &-image {
      height: 300px;
      width: 100%;
      object-fit: cover;
      border-radius: $tzo-radius;
    }
  }
}

.sheet-content {
  height: calc(100vh - 127px); // 60px navbar + 65px sheet toggle + 2px content separator (hr)
  overflow-y: auto;
}

.vh-100-page {
  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - $nav-height);
  }

  @include media-breakpoint-down(lg) {
    min-height: calc(100vh - ($mobile-topbar + $mobile-nav-height));
  }

  &-breadcrumbs {
    @include media-breakpoint-up(lg) {
      min-height: calc(100vh - ($nav-height + $inline-breadcrumbs-height));
    }

    @include media-breakpoint-down(lg) {
      min-height: calc(100vh - ($mobile-topbar + $inline-breadcrumbs-height + $mobile-nav-height));
    }
  }
}