.tzo-gallery-container {
    @include media-breakpoint-down(lg) {
        .swiper-slide {
            height: 385px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .overlay {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0;
            transition: .225s;

            &:hover {
                opacity: 1;
                transition: .225s;
            }

            &.active {
                opacity: 1;
            }
        }
    }
}

.tzo-gallery {
    display: flex;
    overflow: hidden;
    gap: .3rem;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }

    @include media-breakpoint-down(lg) {
        flex-direction: column;
    }

    .main {
        @include media-breakpoint-up(lg) {
            width: 50%;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        img {
            width: 100%;

            @include media-breakpoint-up(lg) {
                height: calc(418px);
            }

            @include media-breakpoint-down(lg) {
                height: 300px;
            }

            object-fit: cover;
        }
    }

    .grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: .3rem;

        @include media-breakpoint-up(lg) {
            width: 50%;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        img {
            @include media-breakpoint-up(lg) {
                width: calc(206px);
                height: calc(206px);
            }

            @include media-breakpoint-down(lg) {
                width: calc(50vw - (24px + 0.15rem)); // 24px padding + gap
                height: calc(50vw - (24px + 0.15rem)); // 24px padding + gap
            }

            object-fit: cover;
        }


    }

    .overlay {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: .225s;

        &:hover {
            opacity: 1;
            transition: .225s;
        }

        &.active {
            opacity: 1;
        }
    }
}

.gallery-zoom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 1500;

    .main {
        img {
            @include media-breakpoint-up(lg) {
                height: calc(70dvh - 8px);
            }

            @include media-breakpoint-down(lg) {
                height: calc(100dvw - 56px); // 48px padding modal + 8 gap
            }

            width: 100%;
            object-fit: cover;
            user-select: none;
        }
    }

    .slider {
        width: 100%;

        .swiper-slide {
            position: relative;
            width: 20dvh;
            height: 20dvh;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                user-select: none;
            }

            .active {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.425);
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}