.pe-lang-fluid-nav {
    .dropdown-menu {
        left: -35% !important;
    }
}

.bg-light-dark {
    background-color: #3C3C3C;
    color: white;
}

@media only screen and (min-width: 992px){
    .custom-height {
        min-height: calc(100vh - 59px) !important;
    }
}

.custom-height {
    min-height: calc(100vh - 56px);
}

.coming-align-items {

    a {
        color: white !important;
    }

    @media only screen and (max-width: 768px) {
        img { width: 50%; }
    }

    @media only screen and (min-width: 769px) and (max-width: 991px) {
        img { width: 70%; }
    }


    @media only screen and (min-width: 1200px) {
        img { transform: translateY(-9.5vh) }
        h2 { transform: translateY(-14vh) }
        #dossier { transform: translateY(-9.5vh) }
        .coming-form-align { position: absolute; transform: translateY(38vh); }
    }

    @media only screen and (min-width: 1400px) {
        img { transform: translateY(-9.5vh) }
        h2 { transform: translateY(-14vh) }
        #dossier { transform: translateY(-9.5vh) }
        .coming-form-align { position: absolute; transform: translateY(39vh); }
    }

    @media only screen and (min-width: 1920px) {
        img { transform: translateY(-9.5vh) }
        h2 { transform: translateY(-14vh) }
        #dossier { transform: translateY(-9.5vh) }
        .coming-form-align { position: absolute; transform: translateY(36vh); }
    }
}