#wrapper {
  display: flex;

  #content-wrapper {
    background-color: $gray-100;
    width: 100%;
    overflow-x: hidden;

    #content {
      flex: 1 0 auto;
    }
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  .page {
    a {
      display: flex;
      font-family: "inter-medium";
      align-items: center;
      justify-content: center;
      padding: .5rem;
      background-color: rgba(75, 70, 92, 0.08);
      border-radius: 6px;
      color: $coal;
      min-width: 40px;
      min-height: 40px;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      user-select: none;

      &:hover,
      &:focus {
        background-color: rgba(75, 70, 92, 0.16) !important;
      }

    }

    &.selected {
      a {
        background-color: $coal !important;
        color: $white !important;
      }
    }
  }
}