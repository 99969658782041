.title-nav-position-home {
    @media only screen and (min-width: 992px) {
        transform: translate(-50%, -50%) !important;
        position: absolute;
        left: 50%;
        top: 50%;

        #logo {
            width: 200px;
        }
    }
}

.navbar-brand {
    @include media-breakpoint-down(xs) {
        margin-right: 0;
    }

    img {
        @media only screen and (max-width: 991px) {
            padding-bottom: 5px;
        }
    }
}

.nav-responsive-fluid {

    width: 100% !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;

}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 5rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 0.85rem;
    color: #000;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e3e6f0;
    border-radius: 0.35rem;
}

.nav-collapse {
    width: 100%;
}

@media only screen and (min-width: 992px) {
    #logo {
        width: 200px;
    }
}

#logo {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 200px;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 200px;
    }

    @media only screen and (max-width: 575px) {
        width: 150px;
    }
}

#logo-soon {

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 200px;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 200px;
    }

    @media only screen and (max-width: 575px) {
        width: 150px;
    }
}

.navArticle {
    .navImgArticle {
        position: relative;

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
            height: 125px;
        }

        @media only screen and (min-width: 1200px) and (max-width: 1399px) {
            height: 150px;
        }

        @media only screen and (min-width: 1400px) and (max-width: 1919px) {
            height: 180px;
        }

        height: 210px;
        background-size: cover;
        background-position: top;
    }

    .dropdown-item {
        white-space: break-spaces;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
        }
    }

    a {
        white-space: normal;
    }

    a:hover {
        font-weight: bold;
        background-color: none !important;
    }
}

.nav-articles {
    .content-card {
        .photo-sm {
            height: 200px;
        }

        .bottom-card {
            p {
                display: block;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis
            }
        }
    }

    article {
        width: 100%;

        p {
            display: block;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1 !important;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: $font-size-base !important;
        }

        .stamp {
            top: 5px;
            left: 5px;
            width: 75px;
        }
    }

    .content-picture {
        height: 200px !important;
    }
}

.dropdown-toggle::after {
    @include media-breakpoint-up(md) {
        display: none !important;
    }
}

.searchInput {
    font-family: "norms";

    @include media-breakpoint-up(lg) {
        width: 185px;
    }

    @include media-breakpoint-up(xl) {
        width: 250px;
    }

    @include media-breakpoint-up(xxl) {
        width: 275px;
    }

    input,
    span {
        background-color: #f7f7f7 !important;
        border: none;
    }

    input {
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }

    span {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
    }

    input:focus {
        box-shadow: none;
    }
}

.nav-item {
    white-space: nowrap;
}

#guideDropdown {
    .dropdown-menu {
        border-radius: 3px;
    }
}

.mobile-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 50px;
    z-index: 1025;
    overflow-x: hidden;

    &.show {
        bottom: 0px;
        transition: .255s;
    }

    &.hide {
        transition: .255s;
        bottom: -62px;
    }

    &.light {
        background-color: $white;
        color: $black;
    }

    &.dark {
        background-color: $dark-brown;

        a {
            color: $white;
        }
    }

    .section {
        flex: 1 0 0px;
        opacity: 75%;
        transition: .225s;

        .light {
            opacity: 55%;
        }

        .dark {
            opacity: 85%;
        }

        &.active {
            opacity: 100%;
            transition: .225s;

            .light {
                &.bottom-nav-ico {
                    fill: $primary;
                    stroke: black;
                }

                &.must {
                    fill: $primary;
                    stroke: black;
                }

                &.tzo {
                    circle {
                        fill: $primary;
                    }

                    path {
                        stroke: black;
                        stroke-width: .125;
                    }
                }

                &.guide {
                    .background {
                        fill: $primary;
                    }

                    .outline {
                        fill: black;
                    }
                }

                &.favorite {
                    stroke: black;
                    fill: $primary;
                }

                &.explore {
                    stroke: black;
                    fill: $primary;
                }
            }

            .dark {
                &.bottom-nav-ico {
                    stroke: #777262;
                    fill: #E9CE2C;
                }

                &.must {
                    stroke: #777262;
                    fill: #E9CE2C;
                }

                &.tzo {
                    circle {
                        fill: #E9CE2C;
                    }

                    path {
                        stroke: #777262 !important;
                        fill: #777262 !important;
                        stroke-width: .125;
                    }
                }

                &.guide {
                    .background {
                        fill: #E9CE2C;
                    }

                    .outline {
                        fill: #777262 !important;
                        stroke: #8a8471;
                    }
                }

                &.favorite {
                    stroke: #777262;
                    fill: #E9CE2C;
                }

                &.explore {
                    stroke: #777262;
                    fill: #E9CE2C;
                }
            }

            &.dark {
                color: #E9CE2C !important;
            }
        }

        .light {
            &.bottom-nav-ico {
                fill: black;
            }

            &.bottom-nav-ico-stroke {
                stroke: black;
            }

            &.must {
                fill: none;
                stroke: black;
                stroke-width: 1.5;
                transition: .225s;
            }

            &.tzo {
                circle {
                    fill: none;
                }

                path {
                    stroke: black;
                    stroke-width: .125;
                }
            }

            &.guide {
                .background {
                    fill: none;
                }

                .outline {
                    fill: black;
                }
            }

            &.favorite {
                stroke: black;
                fill: none;
            }

            &.explore {
                stroke: black;
                fill: none;
                stroke-width: 1.4;
            }
        }

        .dark {
            &.bottom-nav-ico {
                fill: black;
            }

            &.bottom-nav-ico-stroke {
                stroke: black;
            }

            &.must {
                fill: none;
                stroke: white;
                stroke-width: 1.65;
                transition: .225s;
            }

            &.tzo {
                circle {
                    fill: none;
                }

                path {
                    stroke: white;
                    fill: white;
                    stroke-width: .075;
                }
            }

            &.guide {
                .background {
                    fill: none;
                }

                .outline {
                    fill: white;
                    stroke: white;
                    stroke-width: .2;
                }
            }

            &.favorite {
                stroke: white;
                fill: none;

                path {
                    stroke-width: 1.7;
                }
            }

            &.explore {
                stroke: white;
                fill: none;
                stroke-width: 1.65;
            }
        }
    }

    .small {
        font-size: $font-size-base * 0.6;
    }
}