.login-container {
	.background-container {
		.bg-img {
			width: 100%;
			height: calc(100dvh - $nav-height);
			object-fit: cover;
		}

		.logo {
			width: 55%;
		}

		.register-content {
			height: calc(100dvh - $nav-height);
		}

		.recover-content {
			height: calc(100dvh - $nav-height);

			.background {
				height: 100%;
				object-fit: cover;
				object-position: bottom;
			}

			.overlay {
				background-color: rgba(0, 0, 0, 0.2);
			}
		}
	}

	.login-content {

		@media only screen and (min-width: 1537px) {
			width: 45% !important;
		}

		@include media-breakpoint-only(xxl) {
			width: 55%;
		}

		@include media-breakpoint-only(xl) {
			width: 55%;
		}

		@include media-breakpoint-only(lg) {
			width: 60%;
		}

		@include media-breakpoint-down(lg) {
			width: 100%;
		}

		input {
			border: 1px solid $dark-brown;
			box-shadow: none;
			background-color: inherit;
			border-radius: 0;
		}
	}

	.google-logo {
		width: 20px;
		height: 20px;
		background-image: url('../../../public/img/svg/google.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}

	.facebook-btn {
		color: #1877F2;
	}

	.facebook-logo {
		width: 22px;
		height: 22px;
		background-image: url('../../../public/img/svg/facebook-logo.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}

	.apple-logo {
		width: 22px;
		height: 22px;
		background-image: url('../../../public/img/svg/appleid.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}

	.background {
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			// background: linear-gradient(0.19deg, rgba(0, 0, 0, 0.3) 38.95%, rgba(0, 0, 0, 0) 108.22%)
			background: rgba(0, 0, 0, 0.3)
		}

		img,
		video {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	.form-check-input {
		align-self: center;
	}
}

.separator {
	display: flex;
	align-items: center;
	text-align: center;

	&::before,
	&::after {
		content: '';
		flex: 1;
		border-bottom: 1px solid #C1C0C0;
	}

	&:not(:empty)::before {
		margin-right: 1em;
	}

	&:not(:empty)::after {
		margin-left: 1em;
	}
}

.underline:after {
	position: absolute;
	content: "";
	width: 25%;
	height: 3.5px;
	background-color: $primary;
	bottom: -16px;
	left: 0;
}

.img-login {
	background-color: #2e2a2b;
	background-size: cover;
	background-position: center;
}

.loginList {
	::marker {
		width: 8px;
		height: 8px;
	}
}

.login-title-icon {
	transform: translate(-15px, -2px);
	z-index: -1;
}


.login-container {
	// height: calc(100vh - 101px); // navbar + breadcrumbs
	// background: url("../../../public/img/promotion/banner-default.mp4");


	// .background {
	// 	&::after {
	// 		content: '';
	// 		position: absolute;
	// 		left: 0;
	// 		top: 0;
	// 		width: 100%;
	// 		height: 100%;
	// 		// background: linear-gradient(0.19deg, rgba(0, 0, 0, 0.3) 38.95%, rgba(0, 0, 0, 0) 108.22%)
	// 		background: rgba(0, 0, 0, 0.3)
	// 	}

	// 	img,
	// 	video {
	// 		height: 100%;
	// 		width: 100%;
	// 		object-fit: cover;
	// 		object-position: center;
	// 	}
	// }

	.form {

		@include media-breakpoint-up(md) {
			min-height: calc(100vh - $nav-height);
		}

		@include media-breakpoint-down(md) {
			min-height: calc(100vh - ($mobile-topbar + $mobile-nav-height));
		}

		.title {
			font-size: $h1-font-size * 1.75;

			@include media-breakpoint-only(xl) {
				font-size: $h1-font-size * 1.55;
			}

			@include media-breakpoint-only(lg) {
				font-size: $h1-font-size * 1.25;
			}

			z-index: 1;
		}

		.subtitle {
			font-size: $h1-font-size * 0.975;

			@include media-breakpoint-only(xl) {
				font-size: $h1-font-size * 0.865;
			}

			@include media-breakpoint-only(lg) {
				font-size: $h1-font-size * 0.865;
			}

			z-index: 1;
		}

		.text-logo {
			span {
				z-index: 1;
			}

			img {
				z-index: 0;
			}
		}

		.translate-logo {
			transform: translate(-54%, -34%);
			z-index: 0;
		}

		.form-card {
			background-color: white;
			-webkit-backdrop-filter: blur(13px);
			backdrop-filter: blur(13px);

			@include media-breakpoint-up(xl) {
				min-height: calc(82.5vh - 101px);
			}

			max-height: 1250px;

			.subtitle {

				@include media-breakpoint-up(md) {
					font-size: $h2-font-size;
				}

				@include media-breakpoint-only(xl) {
					font-size: $h3-font-size;
				}

				@include media-breakpoint-down(md) {
					font-size: 20px;
				}
			}

			.modal-btn-size {
				min-width: 260px;

				@include media-breakpoint-down(md) {
					min-width: 100% !important;
				}
			}

			@include media-breakpoint-down(sm) {

				a,
				span,
				p,
				label {
					font-size: 14px;
				}
			}
		}

		input[type=email],
		input[type=password] {
			border: none;
			border-radius: 0;
			border-bottom: 2px solid rgba(0, 0, 0, 0.5);
			background-color: inherit;
			color: $black;
			font-family: "norms";
			box-shadow: none;
			transition: .225s;
			padding: 0.375rem 0.225rem;

			&:hover,
			&:focus {
				border-bottom: 2px solid rgb(0, 0, 0);
				transition: .225s;
			}

			.form-check-input {
				padding: 8px
			}

			@include media-breakpoint-down(lg) {
				border: none;
				border-radius: 0;
				border-bottom: 2px solid rgba(0, 0, 0, 0.5);

				&:hover,
				&:focus {
					border-bottom: 2px solid rgb(0, 0, 0);
					transition: .225s;
				}
			}
		}

		.error {
			width: 100%;
			height: 6px;
			background: $primary;
			display: block;
		}

		.form-check-input {
			@include media-breakpoint-up(md) {
				width: 1.075rem;
				height: 1.075rem;
			}

			@include media-breakpoint-up(lg) {
				width: 1.15rem;
				height: 1.15rem;
			}

			@include media-breakpoint-up(xl) {
				width: 1.10rem;
				height: 1.10rem;
			}

			@include media-breakpoint-up(xxl) {
				width: 1.45rem;
				height: 1.45rem;
			}

			margin-right: 6px;
			margin-top: 0;
			margin-bottom: 0;
		}

		.facebook {
			background-color: inherit;
			color: #1877F2 !important;
			border: 1px solid $coal !important;

			@include media-breakpoint-down(sm) {
				span {
					font-size: 12px !important;
				}
			}

			.logo {
				width: 22px;
				height: 22px;
				background-image: url('../../../public/img/svg/facebook-logo.svg');
				background-repeat: no-repeat;
				background-size: contain;
			}
		}

		.google {
			background-color: inherit;
			color: #5a5a5a !important;
			border: 1px solid $coal !important;

			@include media-breakpoint-down(sm) {
				span {
					font-size: 12px !important;
				}
			}

			.logo {
				width: 20px;
				height: 20px;
				background-image: url('../../../public/img/svg/google.svg');
				background-repeat: no-repeat;
				background-size: contain;
			}
		}

		.apple {
			background-color: inherit;
			color: #000000 !important;
			border: 1px solid $coal !important;

			@include media-breakpoint-down(sm) {
				span {
					font-size: 12px !important;
				}
			}

			.logo {
				width: 22px;
				height: 22px;
				background-image: url('../../../public/img/svg/appleid.svg');
				background-repeat: no-repeat;
				background-size: contain;
			}
		}

		.center-check {
			display: flex;
			flex-direction: row;
			align-items: center;
			position: relative;
			padding-left: 1.75rem;

			.form-check-input {
				width: 1.25rem !important;
				height: 1.25rem !important;
				margin-left: 0 !important;
				position: absolute;
				left: 0;
			}

			.form-check-input,
			.form-check-label {
				flex-grow: 0;
				user-select: none;
				-webkit-user-select: none;
			}
		}
	}
}