.filterMap {
    .filterMapButton {
        background-color: inherit;
        display: inline-flex;
        border: 0;
        flex-direction: row;
        align-items: center;

        .icon {
            &:hover {
                background-color: #2e2a2b;

                svg {
                    color: $white !important;
                }
            }

            svg {
                color: $black !important;
            }
        }

        .active {
            background-color: #2e2a2b;
            color: $white !important;

            svg {
                color: $white !important;
            }
        }
    }

    .form-check-input {
        border: 1px solid $black;

        &:focus {
            border-color: $dark;
            outline: 0;
            box-shadow: none;
        }

        &:checked {
            background-color: #8fcab9;
            border-color: #8fcab9;
        }
    }

    .form-select {
        &:focus {
            border-color: $dark;
            outline: 0;
            box-shadow: none;
        }
    }

    .separation {
        border-color: $gray-600 !important;
    }
}

.mapContainer {
    @include media-breakpoint-up(xxl) {
        height: 75vh;
    }

    @include media-breakpoint-up(lg) {
        height: 88vh;
    }

    .map {
        @include media-breakpoint-down(lg) {
            height: 50vh;
        }
    }
}

.category-side-bar {
    overflow-y: auto;
    height: 100%;
    max-width: 150px;

    .item {
        transition: .15s;
        border-bottom: 1px solid $black;
        height: 130px;

        img {
            width: 64px;
            height: auto;
            transition: .15s;
        }

        @include media-breakpoint-only(lg) {
            height: 105px;

            p {
                font-size: $font-size-base * .85;
            }

            img {
                width: 48px;
                height: auto;
            }
        }

        @include media-breakpoint-up(md) {
            max-width: 150px;
        }
    }

    .item-last {
        transition: .15s;

        img {
            transition: .15s;
        }
    }

    .item:hover,
    .item-last:hover,
    .item:target,
    .item-last:target {
        background-color: #2e2a2b;
        color: $white;
        transition: .15s;

        img {
            filter: invert(100%);
            transition: .15s;
        }
    }

    .item-active {
        background-color: #2e2a2b;
        color: $white;
        border-bottom: 1px solid $black;
        height: 130px;

        img {
            width: 64px;
            height: auto;
            filter: invert(100%);
        }

        @include media-breakpoint-only(lg) {
            height: 105px;

            p {
                font-size: $font-size-base * .85;
            }

            img {
                width: 48px;
                height: auto;
                filter: invert(100%);
            }
        }
    }
}

.articleContainer {

    .categoryIcon {
        width: 56px;
        height: auto;

        @include media-breakpoint-only(lg) {
            width: 42px;
            height: auto;
        }

        @include media-breakpoint-down(md) {
            width: 38px;
            height: auto;
        }
    }

    .categoryTitle {

        @include media-breakpoint-only(xxl) {
            font-size: $h4-font-size;
        }

        @include media-breakpoint-only(xl) {
            font-size: $h5-font-size;
        }

        @include media-breakpoint-only(lg) {
            font-size: $h6-font-size;
        }

        @include media-breakpoint-down(md) {
            font-size: $h4-font-size;
        }
    }

    .category-container {
        min-height: 75px;
        max-height: 75px;
        box-shadow: 0 0.275rem 0.25rem -0.215rem rgb(58 59 69 / 20%) !important;
    }
}

.space-category-container {
    margin-top: 70px;
}

.articleCard {

    border: 1px solid $coal;

    span,
    a,
    button {
        font-size: $font-size-base * .900 !important;

        @include media-breakpoint-only(lg) {
            font-size: $font-size-base * .650;

        }
    }

    P {
        @include media-breakpoint-only(xxl) {
            font-size: $h5-font-size;
        }

        @include media-breakpoint-only(xl) {
            font-size: $h5-font-size * .85;
        }

        @include media-breakpoint-only(lg) {
            font-size: $h6-font-size;
        }
    }

    .icon {
        width: 18px;
        height: 18px;

        @include media-breakpoint-only(lg) {
            width: 16px;
            height: 16px;
        }
    }

    .imageSite {
        width: 100%;
        object-fit: cover;

        @include media-breakpoint-up(xxl) {
            max-height: 220px;
        }

        @include media-breakpoint-only(xl) {
            max-height: 160px;

        }

        @include media-breakpoint-only(lg) {
            max-height: 145px;
        }

        @include media-breakpoint-only(md) {
            max-height: 175px;
        }

        @include media-breakpoint-down(md) {
            max-height: 140px;
        }
    }
}

.articleContainer .row,
.category-side-bar {
    @include media-breakpoint-up(sm) {
        &::-webkit-scrollbar {
            width: 9px;
            background-color: $gray-200;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
            border-radius: 6px;
            border: 2px solid $gray-200;
        }

        &::-webkit-scrollbar-thumb:active {
            background-color: $gray-800;
        }
    }
}

.infoBox {
    height: auto;

    .info-window {

        .siteImage {
            width: 100%;
            height: 140px;

            @include media-breakpoint-down(sm) {
                height: 115px;
            }

            object-fit: cover;
            object-position: center;
        }

        .card-img-cover {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.40);
        }

        img,
        svg {
            z-index: 1;
        }

        .icon {

            stroke: white;

            width: 16px;
            height: 16px;

            @include media-breakpoint-only(lg) {
                width: 14px;
                height: 14px;
            }
        }
    }
}

.img-white {
    filter: invert(100%);
}

.form-check-input {
    border: 1px solid $black;

    &:focus {
        border-color: $dark;
        outline: 0;
        box-shadow: none;
    }

    &:checked {
        background-color: $dark-green;
        border-color: $dark-green;
    }
}

.custom-cluster {
    font-family: "tt-norms-bold" !important;
    color: #fff !important;
}

.slider-map {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    .card {
        flex: 0 0 auto;
    }

    .active-category {
        border: 1px solid $dark !important;
        background-color: #2e2a2b !important;
        color: white;

        img {
            filter: invert(100%);
        }
    }

    .category-title {
        font-size: 1.025rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    &::-webkit-scrollbar {
        height: 0px;
        background-color: $gray-200;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 6px;
        border: 2px solid $gray-200;
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: $gray-800;
    }
}

.display-on-map {
    z-index: 1000;
}

.map-input-search {
    width: 25%;
}

.overlay-map {
    background-color: #ffffffc5;
}

.ubication-center {
    @include media-breakpoint-up(lg) {
        bottom: 120px !important;
        right: 10px !important;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: 10px;
        margin-right: 10px;
    }
}

.item-height {
    height: 35px;
}

.card-shadow-explore {
    box-shadow: 0.025rem 0.040rem 0.3rem 0.09rem rgb(58 59 69 / 30%) !important;
}

.card-shadow-map {
    box-shadow: 0.025rem 0.040rem 0.3rem 0.09rem rgb(58 59 69 / 45%) !important;
}

.sidebar-shadow {
    box-shadow: 0.65rem 0 0.75rem -0.5rem rgba(58, 59, 69, 0.65) !important;
}

.map-control-shadow {
    box-shadow: 0 0.05px 0.25rem 0.125rem rgb(58 59 69 / 25%) !important;
}

.explore-container {
    width: 100%;
    position: relative;

    @include media-breakpoint-up(lg) {
        height: calc(100vh - ($nav-height));
    }

    @include media-breakpoint-down(lg) {
        height: calc(100dvh - ($mobile-topbar));
    }

    &.fullscreen {
        position: relative;
        height: 100vh;
        z-index: 1500;
    }

    .controls-search {
        left: .5rem;
        top: .75rem !important;
    }

    .controls-container {
        position: absolute;
        z-index: 2;

        @include media-breakpoint-down(sm) {
            left: .5rem;
            top: calc(.75rem + 72px); // 68px search box + 4px gap

            &.open {
                width: calc(100% - 1rem);
                z-index: 1005;
            }
        }

        @include media-breakpoint-up(sm) {
            left: .75rem;
            // top: calc(.5rem + 74px); // 70px search box + 4px gap (80x70)
            top: calc(.75rem + 79px); // 75px search box + 4px gap (90x75)
        }

        .control-wrapper {
            background-color: $dark-brown;

            .search {
                padding-right: .75rem;

                @include media-breakpoint-up(sm) {
                    display: flex;
                    align-items: center;

                    form {
                        height: 75%;
                    }

                    input {
                        border: none;
                        background-color: $beige;
                        width: 300px;
                        border-radius: 0px;
                        color: $dark-green !important;

                        &::placeholder {
                            color: $dark-green;
                            opacity: .65;
                        }
                    }

                    .clear {
                        background-color: $beige;
                    }
                }

                @include media-breakpoint-down(sm) {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;

                    form {
                        height: 75%;
                    }

                    input {
                        border: none;
                        border-radius: 0px;
                        background-color: $beige;
                        font-size: 16px !important;
                        color: $dark-green !important;

                        &::placeholder {
                            color: $dark-green;
                            opacity: .65;
                        }
                    }

                    .clear {
                        background-color: $beige;
                    }
                }
            }

            &.charge {
                transition: background-color .225s;
            }

            @include media-breakpoint-up(sm) {
                &.drop-desktop {
                    background-color: $dark-brown;
                }
            }

            .category-drop {
                background-color: rgba(234, 234, 234, 0.1);
                padding: 0 0.20rem;
                overflow-y: scroll;
                max-height: calc(40vh - 150px); //offset navbar + sheet

                &::-webkit-scrollbar {
                    display: none;
                }

                @include media-breakpoint-up(sm) {
                    &::-webkit-scrollbar {
                        width: 3px;
                        background-color: $gray-300;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: rgb(170, 170, 170);
                        border-radius: 6px;
                        border: 0px solid $gray-200;
                    }

                    &::-webkit-scrollbar-thumb:active {
                        background-color: $gray-800;
                    }
                }

                .category {
                    @include media-breakpoint-down(sm) {
                        width: 64px;
                        min-height: 62px;
                        background-color: $white;
                    }

                    @include media-breakpoint-up(sm) {
                        // width: 98px;
                        width: 76px;
                        // min-height: 80px;
                        background-color: $white;
                    }

                    box-shadow: 0px 1.115px 2.6936px rgba(0, 0, 0, 0.25);
                    transition: .225s;

                    img,
                    svg {
                        @include media-breakpoint-up(sm) {
                            width: 34px;
                        }

                        @include media-breakpoint-down(sm) {
                            width: 32px;
                        }
                    }

                    span {
                        @include media-breakpoint-down(sm) {
                            font-size: $small-font-size * 0.625;
                        }

                        @include media-breakpoint-up(sm) {
                            font-size: $small-font-size * .7;
                        }
                    }

                    &.active {
                        background-color: $dark-green;
                        color: $white;

                        img {
                            filter: invert(100%);
                        }

                        svg {
                            color: $white;
                        }

                        transition: .225s;
                    }
                }
            }

            .slider-container {

                @include media-breakpoint-down(sm) {
                    height: calc(35vh - 150px);
                }

                @include media-breakpoint-up(sm) {
                    height: calc(35vh - 150px);
                }

                .rc-slider-track {
                    background-color: $coal;
                }

                .rc-slider-handle {
                    border: solid 5px $coal;
                    background-color: $primary;
                    opacity: 1;
                    width: 18px;
                    height: 18px;
                    margin-left: -7px;
                }

                .rc-slider-dot-active {
                    border: 2px solid $coal;
                    background-color: $primary;
                }

                .rc-slider-handle-dragging {
                    box-shadow: 0 0 0 5px $primary !important;
                }

                .rc-slider-mark-text {
                    @include media-breakpoint-down(sm) {
                        font-size: $small-font-size;
                    }
                }

                .rc-slider-handle:focus-visible {
                    box-shadow: 0 0 0 3px $primary;
                }
            }

            &.active {
                background-color: $dark-green;
                color: $white;

                img {
                    filter: invert(100%);
                }

                svg {
                    color: $white;
                }
            }

            &.fullscreen {
                background-color: $dark-green !important;
            }
        }

        .control-icon {
            color: $white;

            @include media-breakpoint-up(sm) {

                width: 38px;
                height: 67px;

                &.open {
                    width: 82px;
                    height: 67px;
                }

                img,
                svg {
                    width: 26px;
                    height: 26px;
                    color: black;
                }

                span {
                    font-size: $small-font-size * .9;
                }
            }

            &.drop-desktop {
                background-color: rgb(234, 234, 234);
            }

            @include media-breakpoint-down(sm) {

                min-width: 42px;
                min-height: 60px;

                &.open {
                    min-width: 70px;
                    min-height: 60px;
                }

                img,
                svg {
                    width: 24px;
                    color: $black;
                }

                span {
                    font-size: $small-font-size * 0.675;
                }
            }

            &.drop {
                @include media-breakpoint-down(sm) {
                    box-shadow: 0px 2.05px 2.65px rgba(0, 0, 0, 0.25);
                }
            }

            img, svg {
                filter: invert(100%);
            }
        }
    }

    .desktop-card {
        position: absolute;
        right: .75rem;
        bottom: 0;
        border-top-left-radius: $tzo-radius;
        border-top-right-radius: $tzo-radius;
        box-shadow: -3px -2px 8px rgba(0, 0, 0, 0.15);
        overflow-y: auto;
        transition: .3s;
        z-index: 1;

        @include media-breakpoint-up(lg) {
            // max-width: 500px;
            width: 650px;

            &.open {
                max-height: calc(100% - 0.75rem);
                height: auto !important;
            }

            &.close {
                height: 185px;

            }
        }

        .new-content-card {
            .content-picture {
                height: 240px;
            }
        }

        .control {
            z-index: 10;

            svg {
                color: $gray-600;
                cursor: pointer;
                transition: .225s;

                &:hover {
                    color: $coal;
                    transition: .225s;
                }
            }
        }

        .category-icon {
            width: 48px;
            height: auto;
        }

        .card-list {
            min-height: 295px !important;
            height: 100%;
            overflow: hidden;
            box-shadow: 1.71747px 3.43494px 10.3048px rgba(0, 0, 0, 0.2);

            .background {
                width: 100%;
                max-height: 145px;
                min-height: 145px;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                object-fit: cover;
            }

            .more {
                white-space: nowrap;
                flex-grow: 3;
            }

            .action {
                flex-grow: 1;
            }

            .btn-dark-ligth-outline {
                opacity: 65%;
                border: 1px solid rgba(46, 42, 43, 0.65) !important;
            }

            .btn-dark-ligth-outline:hover svg {
                color: white;
            }
        }

        .sites-content {
            .content-card {

                .photo-sm,
                .photo-lg {
                    height: 240px;
                }

                .bottom-card {
                    max-height: 100px;
                    height: auto !important;
                }
            }
        }

        .overlay {
            height: 45px;
            width: 100%;
            bottom: -2px;
            backdrop-filter: blur(9px);
            background-color: rgba(255, 255, 255, 0.05);
            z-index: 5;

            .mouse {
                position: relative;
                width: 20px;
                height: 75%;
                border: 2px solid $coal;
                border-radius: 15px;

                .scroll {
                    position: absolute;
                    width: 2.65px;
                    height: 9px;
                    bottom: 12.5%;
                    transform: translateX(-50%) !important;
                    left: 50%;
                    background-color: $coal;
                    border-radius: 15px;
                    animation-name: scrollAnimation;
                    animation-duration: 1.725s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in-out;
                    animation-delay: .250;

                    @keyframes scrollAnimation {
                        0% {
                            bottom: 18.5%;
                        }

                        25% {
                            bottom: 8%;
                        }

                        50% {
                            bottom: 18.5%;
                        }

                        100% {
                            bottom: 18.5%;
                        }
                    }
                }
            }
        }

        .pagination {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .page {
                a {
                    display: flex;
                    font-family: "norms-bold";
                    align-items: center;
                    justify-content: center;
                    padding: .5rem;
                    background-color: rgba(75, 70, 92, 0.08);
                    border-radius: 6px;
                    color: $coal;
                    min-width: 40px;
                    min-height: 40px;
                    text-align: center;
                    text-decoration: none;
                    cursor: pointer;
                    user-select: none;

                    &:hover,
                    &:focus {
                        background-color: rgba(75, 70, 92, 0.16) !important;
                    }

                }

                &.selected {
                    a {
                        background-color: $coal !important;
                        color: $white !important;
                    }
                }
            }
        }

        &::-webkit-scrollbar {
            width: 12px;
            background-color: $gray-200;
            border-top-right-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
            border-radius: 6px;
            border: 2px solid $gray-200;
        }

        &::-webkit-scrollbar-thumb:active {
            background-color: $gray-800;
        }

        .sponsor {
            object-position: 0% 82.5%;

            button {
                bottom: 12.5%;
                border: 1px solid white;
                background-color: rgba(255, 255, 255, 0.25);
                opacity: 0;
                transition: 0.225s;
            }

            &:hover {
                button {
                    opacity: 1;
                    transition: 0.225s;
                }
            }
        }
    }


    .infoBox {
        @include media-breakpoint-up(lg) {
            width: 285px !important;
        }

        @include media-breakpoint-down(lg) {
            width: 260px !important;
        }

    }
}

.explore-sheet {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    // border-top: 1px solid black;
    z-index: 1005;
    max-height: calc(100dvh - ($mobile-topbar));
    min-height: 105px;
    box-shadow: 0 -0.065rem 0.55rem 0 rgba(46, 41, 42, 0.35) !important;

    &.close {
        height: 105px;
        overflow-y: hidden;
        transition: .5s ease-in-out;

        .swiper {
            // overflow: auto !important;
        }
    }

    &.open {
        @include media-breakpoint-up(md) {
            height: calc(100dvh - $nav-height);
        }

        @include media-breakpoint-down(md) {
            height: calc(100dvh - ($mobile-topbar));
        }

        transition: .5s ease-in-out;
        overflow: hidden;
    }

    .drag-arrow {
        opacity: 25%;
        height: 15px;
    }

    .drag-icon {
        background-color: #000;
        border-radius: 3px;
        height: 3px;
        opacity: 50%;
        transition: .15s ease-in-out;
        width: 15%;
    }

    .drag-arrow {
        opacity: 25%;
        height: 15px;
    }

    hr {
        color: $coal;
        opacity: .5;
    }

    .sheet-content {
        height: calc(100vh - ($mobile-topbar + 54px)); // 60px navbar + 65px sheet toggle + 2px content separator (hr)
        overflow-y: auto;

        &.charger {
            height: auto !important;
            overflow: unset !important;
        }
    }

    .sites-horizontal {
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .card-list {
        overflow: hidden;
        box-shadow: 1.71747px 3.43494px 10.3048px rgba(0, 0, 0, 0.2);
        height: 100%;

        .background {
            width: 100%;
            height: 145px !important;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            object-fit: cover;
        }

        .more {
            white-space: nowrap;
            flex-grow: 3;
            // font-size: $small-font-size;
        }

        .action {
            flex-grow: 1;
        }

        .btn-dark-ligth-outline {
            opacity: 65%;
            border: 1px solid rgba(46, 42, 43, 0.65) !important;
        }

        .btn-dark-ligth-outline:hover svg {
            color: white;
        }

    }

    .swiper {
        overflow-y: unset;
    }

    .sponsor {
        object-position: 0% 82.5%;

        .card-video {
            height: 23.5vh;
            width: 100%;
        }

        &:hover {
            button {
                opacity: 1;
                transition: 0.225s;
            }
        }
    }

    .category-icon {
        width: 48px;
        height: auto;
    }

    .sites-container {
        .content-card {
            .bottom-card {
                height: 94px;
            }
        }
    }

    // .new-content-card {
    //     .content-picture {
    //         @include media-breakpoint-down(lg) {
    //             width: 100%;
    //             height: 40vw;
    //         }
    //     }

    //     p, span {
    //         font-size: $font-size-base !important;
    //     }
    // }

}

.explore-preview-card {
    a:hover {
        color: $black !important;
    }

    .header {
        object-fit: cover;
        object-position: center;
        width: 100%;

        @include media-breakpoint-down(md) {
            height: 250px;
            max-height: 250px;
            -webkit-line-clamp: 5 !important;
        }

        @include media-breakpoint-only(md) {
            height: 235px;
            max-height: 235px;
        }

        @include media-breakpoint-only(lg) {
            height: 310px;
            max-height: 310px;
        }

        @include media-breakpoint-only(xl) {
            height: 215px;
            -webkit-line-clamp: 4 !important;
        }

        @include media-breakpoint-up(xxl) {
            height: 250px;
            max-height: 250px;
        }

        @media only screen and (min-width: 1920px) {
            height: 200px;
            max-height: 200px;
        }
    }

    .logo-category {
        width: 28px;
        height: 28px;

        img {
            min-width: 100%;
            filter: invert(45%);
        }
    }

    .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @include media-breakpoint-up(xl) {
            font-size: $h5-font-size * .9;
        }
    }

    .description {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            font-size: 0.925rem;
            -webkit-line-clamp: 3 !important;
        }

        @include media-breakpoint-only(md) {
            font-size: 0.825rem;
            -webkit-line-clamp: 3 !important;
        }

        @include media-breakpoint-only(lg) {
            font-size: 0.9rem;
            -webkit-line-clamp: 3 !important;
        }

        @include media-breakpoint-only(xl) {
            font-size: 0.9rem;
            -webkit-line-clamp: 3 !important;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 0.825rem;
            -webkit-line-clamp: 3 !important;
        }

        @media only screen and (min-width: 1920px) {
            font-size: 0.775rem;
            -webkit-line-clamp: 3 !important;
        }
    }

    .stamp {
        z-index: 2;
        width: 64px;
        height: auto;
        position: absolute;
        top: 6px;
        right: 6px;

        @include media-breakpoint-only(xl) {
            width: 56px;
            top: 10px;
            right: 6px;
        }
    }

    .category {

        @include media-breakpoint-up(xl) {
            flex-direction: row !important;
        }

        @media only screen and (min-width: 1920px) {
            flex-direction: row !important;
        }

        @include media-breakpoint-only(md) {
            flex-direction: row !important;
        }

        p {
            font-size: $small-font-size;
        }
    }

    .card-button {
        @media only screen and (min-width: 1920px) {
            flex-grow: 0;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 185px;
            flex-grow: 1;
        }
    }
}

.map-card {
    overflow: hidden;
    box-shadow: 1.71747px 3.43494px 10.3048px rgba(0, 0, 0, 0.2);
    height: 100%;

    &.site {

        p,
        button {
            font-size: $font-size-base;

            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }

            @include media-breakpoint-up(sm) {
                font-size: $font-size-base;
            }
        }
    }

    .fa-xmark path {
        stroke: $black;
        stroke-width: 24px;
    }

    svg {
        z-index: 1;
    }

    .background {
        width: 100%;

        @include media-breakpoint-up(lg) {
            max-height: 165px;
        }

        @include media-breakpoint-down(lg) {
            height: 140px;
        }

        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        object-fit: cover;
    }

    .more {
        white-space: nowrap;
        flex-grow: 2;
        // font-size: $small-font-size;
    }

    .action {
        flex-grow: 1;
    }

    .btn-dark-ligth-outline {
        opacity: 65%;
        border: 1px solid rgba(46, 42, 43, 0.65) !important;
    }

    .btn-dark-ligth-outline:hover svg {
        color: white;
    }

    .card-img-cover {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.connector-sheet {
    p {
        font-size: $font-size-base
    }

    .control {
        border: 1px solid #A8A6A6;
        padding: .5rem;
        margin-bottom: .5rem;

        svg {
            color: #A8A6A6;
        }

        &.active {
            background-color: #ECECEC;
            padding: .5rem .5rem .5rem .5rem;
            margin-bottom: 0 !important;
            border: 1px solid #ECECEC;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;

            & svg {
                color: white !important;
                border: 2px solid #A8A6A6;
                border-radius: 50%;
                background-color: #A8A6A6;
                width: 24px !important;
            }
        }
    }

    .content {
        background-color: #ECECEC;
        border-top-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;

        span {
            font-size: 14px;
        }

        img {
            height: 32px;
        }
    }

    .info {

        p,
        li {
            font-size: 13px;

            &:last-child {
                margin: 0 !important;
            }
        }
    }

    .warning {
        font-size: 11px;
    }
}

.on-boarding {

    .map-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1002;
        background-color: rgba(0, 0, 0, 0.65) !important;
    }

    .on-boarding-explain {
        max-width: 325px;

        @include media-breakpoint-down(sm) {
            max-width: 245px;

            span {
                font-size: 18px !important;
            }
        }
    }

    .show {
        z-index: 1005;
        transition: 0s !important;
    }

    .controls-container {

        .control-wrapper {
            transition: 0s !important;
        }

        .hide {
            // background-color: rgb(161 159 159);
            // background-color: rgb(89 89 89);
        }
    }
}

.chargeSheet {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1002;
    background-color: $white;
    overflow-x: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: .5s ease-in-out;

    .header {
        width: 100%;
        object-fit: cover;

        @include media-breakpoint-up(md) {
            height: 38vh;
        }

        @include media-breakpoint-down(md) {
            height: 32.5vh;
        }
    }

    &.open {
        height: calc(100%); // 41px breacrubms
    }

    &.close {
        height: 0px;
    }

    .fa-xmark path {
        stroke: $black;
        stroke-width: 24px;
    }
}

.site-detail {

    @include media-breakpoint-down(lg) {
        height: calc(100vh - (56px + $mobile-topbar));
    }

    .site-content {
        @include media-breakpoint-down(lg) {
            min-height: calc(100% - (54px + 72px + 50px)); // 54px sheet toggle + 2px content separator (hr) + 72px sticky menu
        }
    }

    .shadow-btn {
        &-left {
            box-shadow: inset 0.185rem 1px 0.25rem -0.15rem rgba(58, 59, 69, 0.325) !important;
        }

        &-right {
            box-shadow: inset -0.185rem 1px 0.25rem -0.15rem rgba(58, 59, 69, 0.325) !important;
        }
    }

    .header {
        position: sticky;
        top: 0;
        left: 0;
        background-color: $white;
        z-index: 2;
    }

    .actions-menu {
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: $white;
        z-index: 2;

        .see-more {
            &:hover {

                .new-link-laurel::before,
                .new-link-brown::before,
                .new-link-coal::before {
                    width: 0%;
                    transition: 0.225s;
                }
            }
        }

        &.charger {
            @include media-breakpoint-up(lg) {
                height: 60px;
            }
        }

        hr {
            color: $light-coal;
            opacity: 1;

            height: 1.5px;

            &.vertical-separator {
                height: 100% !important;
            }
        }
    }

    .action {
        width: 42px;
        height: 42px;

        @include media-breakpoint-down(lg) {
            width: 32px;
            height: 32px;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .site-image {
        width: 100%;
        // height: 100%;
        position: relative;
        object-fit: cover;

        @include media-breakpoint-up(lg) {
            max-height: 405px;
            height: 42.5vh;
        }

        @include media-breakpoint-down(lg) {
            max-height: 275px;
        }
    }

    .swiper {
        img {
            @include media-breakpoint-down(lg) {
                height: 40vh;
                max-height: 265px;
                object-fit: cover;
            }
        }

        .swiper-wrapper {
            .swiper-slide:first-child {
                padding-left: 24px;
            }

            .swiper-slide:last-child {
                padding-right: 24px;
            }
        }

        .swiper-button-prev {
            background-color: rgba(0, 0, 0, 0.6) !important;
            height: 105%;
            width: 36px;
            top: 50% !important;
            left: 0% !important;
            transform: translateY(-45%) !important;
            color: white !important;
            font-size: 30px !important;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
            font-size: 30px !important;
        }

        .swiper-button-next {
            background-color: rgba(0, 0, 0, 0.6) !important;
            height: 105%;
            width: 36px;
            top: 50% !important;
            right: 0% !important;
            transform: translateY(-45%) !important;
            color: white !important;
            font-size: 30px !important;

            ::after {
                font-size: 30px !important;
            }
        }

        .swiper-button-disabled {
            display: none;
        }
    }

    .react-horizontal-scrolling-menu--wrapper {
        @include media-breakpoint-up(lg) {
            flex-grow: 1;
        }

        // justify-content: center;
        @include media-breakpoint-down(lg) {
            height: 225px;
        }
    }

    .react-horizontal-scrolling-menu--inner-wrapper {
        // height: 100%;

        @include media-breakpoint-up(xxl) {
            height: 45vh;
            max-height: 405px;
            position: relative;
        }
    }

    .react-horizontal-scrolling-menu--separator {
        margin-right: 8px !important;
    }

    .react-horizontal-scrolling-menu--item {
        @include media-breakpoint-up(lg) {
            img {
                width: 500px;
                min-height: 100%;
                object-fit: cover;
            }
        }

        @include media-breakpoint-down(lg) {
            img {
                height: 225px;
                object-fit: cover;
            }
        }
    }

    .react-horizontal-scrolling-menu--arrow-left {
        position: absolute !important;
        transform: translateY(-50%) !important;
        left: 0 !important;
        top: 50% !important;
        height: 100%;
        // margin-left: 4px;

        .custom-control {
            background-color: rgba(0, 0, 0, 0.6) !important;
            // border-radius: 50%;

            svg {
                color: rgb(230, 230, 230) !important;
                width: 14px !important;
            }
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .react-horizontal-scrolling-menu--arrow-right {
        position: absolute !important;
        transform: translateY(-50%) !important;
        right: 0 !important;
        top: 50% !important;
        height: 100%;
        // margin-right: 4px;

        .custom-control {
            background-color: rgba(0, 0, 0, 0.6) !important;
            // border-radius: 50%;

            svg {
                color: rgb(230, 230, 230) !important;
                width: 14px !important;
            }
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .content {
        @include media-breakpoint-up(lg) {
            &::-webkit-scrollbar {
                width: 12px;
                background-color: $gray-200;
                border-top-right-radius: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #c1c1c1;
                border-radius: 6px;
                border: 2px solid $gray-200;
            }

            &::-webkit-scrollbar-thumb:active {
                background-color: $gray-800;
            }

            .charger-table {
                .charger-ico {
                    height: 42px;
                    width: auto;
                }

                .quantity {
                    width: 72px;
                }
            }
        }


    }

    @include media-breakpoint-down(lg) {
        .header {
            .item {
                padding: 10px;
            }
        }
    }

    .custom-fav {
        .fav-container {
            transition: .225s;

            @include media-breakpoint-down(lg) {
                width: 32px;
                height: 32px;
            }

            path {
                fill: $beige !important;
                stroke: $coal !important;
                transition: .255s;
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    path {
                        fill: $coal !important;
                    }

                    // transition: .255s;
                }
            }

            &.active {
                path {
                    fill: $coal !important;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            .fav-icon {
                width: 24px !important;
                height: 24px !important;
            }
        }
    }
}

.charger {

    .charger-table {
        .charger-ico {
            height: 42px;
            width: auto;
        }

        .quantity {
            width: 72px;
        }
    }
}
