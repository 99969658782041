.article-body {
    a {
        color: $black;

        span {
            color: $black;
        }
    }

    img {
        object-fit: cover;
    }
}

.article-image {
    width: 100%;
    height: 100%;
    max-height: 625px;
    object-fit: cover;

    @include media-breakpoint-down(lg) {
        max-height: 50vh;
    }

    &-half {
        width: 100%;
        height: 100%;
        max-height: 525px;
        object-fit: cover;

        @include media-breakpoint-down(lg) {
            max-height: 40vh;
        }
    }
}

.articleImageFirst-author {
    min-height: 96%;
    object-fit: cover;
}

.articleImageFirst {
    object-fit: cover;

    @include media-breakpoint-up(md) {
        min-height: 100%;
    }

    @include media-breakpoint-down(md) {
        height: 55vh;
        max-height: 55vh;
        width: 100%;
    }
}

.articleImgBackground {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media only screen and (min-width: 577px) and (max-width: 767px) {
        height: 350px;
    }

    @media only screen and (max-width: 576px) {
        height: 250px;
    }
}

.qoute-article {
    p {
        margin-bottom: 0;
    }
}

.private-overlay {
    background-image: linear-gradient(to top, $secondary 95%, rgba(0, 0, 0, 0) 100%) !important;
    width: 100%;
    z-index: 1;

    @media only screen and (max-width: 576px) {
        background-image: linear-gradient(to top, $secondary 98.5%, rgba(0, 0, 0, 0) 100%) !important;
    }
}

.private-alert {
    top: 5%;
    width: 100%;

    @media only screen and (max-width: 576px) {
        top: 2.5%;
    }
}

#block2,
#block1 {
    p:last-child {
        margin-bottom: 0 !important;
    }
}

.limitArticleText {
    p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2 !important;
    }
}

.tag-separator {
    opacity: 25%;
    border-right: 2px solid $black;

    &:last-child {
        display: none;
    }
}

.site-info-container {

    @include media-breakpoint-up(md) {
        border-right: 1px solid $black;
    }

    hr {
        height: 1px;
        background-color: $coal;
        opacity: 1;
    }

    &:last-child {
        border: none;
    }

    @include media-breakpoint-only(lg) {
        &:nth-child(2n) {
            border-right: none;

            &:last-child {
                border-right: 0;
            }
        }
    }

    @include media-breakpoint-only(md) {
        &:nth-child(2n) {
            border-right: none;

            &:last-child {
                border-right: 0;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &:nth-child(3n) {
            border-right: none;
            // padding-right: 3rem;

            &:last-child {
                border-right: 0;
            }
        }
    }
}

.site-info-review-container {
    @include media-breakpoint-up(md) {
        border-right: 1px solid $black;
    }

    hr {
        height: 1px;
        background-color: $coal;
        opacity: 1;
    }

    &:last-child {
        border: none;
    }

    @include media-breakpoint-only(lg) {
        &:nth-child(2n) {
            border-right: none;

            &:last-child {
                border-right: 0;
            }
        }
    }

    @include media-breakpoint-only(md) {
        &:nth-child(2n) {
            border-right: none;

            &:last-child {
                border-right: 0;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &:nth-child(2n) {
            border-right: none;
            // padding-right: 3rem;

            &:last-child {
                border-right: 0;
            }
        }
    }
}

.review {
    .stamp-icon {
        width: 100px;
        height: auto;
    }

    .is-sticky {
        position: fixed;
        top: 10px;
        z-index: 999;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
}

.article-pagination {
    @include media-breakpoint-down(lg) {
        .control-right {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
        }

        .control-left {
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.ranking-pagination {

    .react-horizontal-scrolling-menu--separator {
        margin-right: .85rem !important;
    }

    .ranking {
        border-radius: 50%;
        color: $dark-green;
        background-color: rgba(255, 255, 255);
        min-width: 30px;
        min-height: 30px;
        text-align: center;
        font-size: 14px;
        opacity: 60%;

        &.active {
            opacity: 100%;
        }
    }
}

.review-custom-cards {

    .photo-sm,
    .photo-lg {
        height: 225px !important;
    }

    .bottom-card {
        max-height: 100px !important;
        height: auto !important;
    }
}

.custom-fav {
    .fav-container {
        background-color: transparent !important;

        path {
            stroke: $white !important;
        }
    }
}

.suggest-custom-cards {
    .content-picture {
        @include media-breakpoint-up(xl) {
            height: 250px !important;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
            height: 75vw;
        }
    }
}

.review-stamp {
    @include media-breakpoint-up(lg) {
        position: absolute;
        top: 8px;
        right: 8px;
        object-fit: cover;
        width: 115px;
        height: auto;
    }

    @include media-breakpoint-down(lg) {
        position: absolute;
        top: 8px;
        right: 8px;
        object-fit: cover;
        width: 85px;
        height: auto;
    }
}

.article-limit {
    background: radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(254, 252, 243, 0.81) 0%, rgba(254, 252, 243, 0.18) 100%);
    backdrop-filter: blur(10px);
    height: calc(100% - 65vh);
    border-top: 1px solid rgba(180, 174, 153, 0.2);

    @include media-breakpoint-down(lg) {
        height: calc(100% - 160vh);
        backdrop-filter: blur(6px);
    }


    .sticky-container {
        @include media-breakpoint-down(lg) {
            padding-top: 55px;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 61px;
        }
    }

    .login-container {
        @include media-breakpoint-down(lg) {
            height: calc(100vh - (55px + 62.4px));
        }

        .login-content {
            width: auto !important;
        }

        .container {
            height: calc(100vh - 61px);

            @include media-breakpoint-down(lg) {
                height: calc(100vh - (55px + 62.4px));
            }

            #form-container {
                @include media-breakpoint-up(lg) {
                    height: calc(100vh - (61px));
                }

                @include media-breakpoint-down(lg) {
                    height: calc(100vh - (55px + 62.4px));
                }

                @include media-breakpoint-up(lg) {
                    &::-webkit-scrollbar {
                        width: 4px;
                        border-top-right-radius: 3px;
                        display: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #B4AE99;
                        border-radius: 2px;
                    }

                    &::-webkit-scrollbar-thumb:active {
                        background-color: $gray-800;
                    }
                }
            }
        }
    }
}