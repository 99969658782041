.tagList {
    color: "#9C9C9C" !important;
    border-color: #F5F5F5;

    ol {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin-bottom: 0;

        li {
            font-size: 18px;
            position: relative;
            color: #9C9C9C;
            font-family: "Joane-thin";
        }

        li::after {
            content: '|';
            padding-left: 8px;
            padding-right: 8px;
            font-family: "Joane-bolder";
        }

        li:last-child::after {
            content: '';
        }

        .active {
            color: $black;
            font-family: "Joane-light";
        }
    }
}