.user-pic {
    border-radius: 50%;
    border: 2px solid #53B095;

    @include media-breakpoint-up(lg) {
        width: 125px;
        height: 125px;
    }

    @include media-breakpoint-down(lg) {
        width: 100px;
        height: 100px;
    }
}

.user-pic-edit {
    border-radius: 50%;
    border: 2px solid #53B095;
    width: 60px;
    height: 60px;

    .icon {
        width: 30px;
    }
}

.update {
    // input[type=text] {
    //     border: none;
    //     border-bottom: 1px solid $coal !important;
    //     border-radius: 0;
    //     font-family: norms;
    //     padding-left: .25rem;
    // }

    .form-check-label {
        margin-left: .5rem;
    }

    .form-check-input {
        width: 1.4rem;
        height: 1.4rem;
        background-color: black;
        border: 1px solid white;
        filter: invert(100%);
    }
}

.profile-nav {
    .sections {
        .separator {
            height: auto;
            width: 1px;
            background-color: $coal;
            margin: 0;
            opacity: .25;

            &::after,
            &::before {
                border: none;
            }
        }
    }

    .input-group {
        @include media-breakpoint-up(lg) {
            max-width: 250px;
        }

        border: 2px solid rgba(168, 166, 166, 0.50);
        border-radius: 7px;
    }

    .input-group-text {
        background: none !important;
        // border: 2px solid rgba(168, 166, 166, 0.50);
        // border-top-left-radius: 7px;
        // border-bottom-left-radius: 7px;
        border: none;
    }

    .form-control {
        // border-left: none !important;
        // border: 2px solid rgba(168, 166, 166, 0.50);
        // border-top-right-radius: 7px;
        // border-bottom-right-radius: 7px;
        border: none;
        box-shadow: none;
    }
}

.folder-card {
    width: auto !important;

    transition: box-shadow .175s ease-in-out;

    .header {
        img {
            object-fit: cover;
            height: 80px;
            width: 80px;
        }

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            // background-color: rgba(46, 42, 43, 0.4);
        }

        @include media-breakpoint-down(md) {
            height: 214px;
        }

        @include media-breakpoint-up(md) {
            height: 140px;
        }

        @include media-breakpoint-up(xl) {
            height: 210px;
        }


        .add-icon {
            @include media-breakpoint-down(lg) {
                height: 82px;
            }
        }
    }

    .form-control-color {
        width: 42px;
    }
}

.folder-card-placeholder {
    .header {
        .header-img {
            display: block;

            @include media-breakpoint-down(lg) {
                height: 211px;
            }

            @include media-breakpoint-up(lg) {
                height: 235px;
            }
        }

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(46, 42, 43, 0.4);
        }

        .add-icon {
            @include media-breakpoint-down(lg) {
                height: 82px;
            }
        }
    }
}

.favourite-search {
    .input-group {
        @include media-breakpoint-up(lg) {
            width: 250px;
        }

        border: 1px solid $light-coal;
        border-radius: 7px;
    }

    .input-group-text {
        background: none !important;
        // border: 2px solid rgba(168, 166, 166, 0.50);
        // border-top-left-radius: 7px;
        // border-bottom-left-radius: 7px;
        border: none;
    }

    .form-control {
        // border-left: none !important;
        // border: 2px solid rgba(168, 166, 166, 0.50);
        // border-top-right-radius: 7px;
        // border-bottom-right-radius: 7px;
        border: none;
        box-shadow: none;
    }
}

.content-manager {
    height: calc(100% - 57px); // 57px top container row

    .left,
    .right {
        max-height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
            background-color: none;
            border-top-right-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $light-coal;
            border-radius: 6px;
            // border: 2px solid $gray-200;
        }

        &::-webkit-scrollbar-thumb:active {
            background-color: $gray-700;
        }
    }

    .photo-lg {
        width: 100%;
        height: 235px !important;
        object-fit: cover;
    }

    .photo-sm {
        width: 100%;
        height: 170px !important;
        object-fit: cover;
    }

    .form-control-color {
        width: 42px;
    }

    .option {
        box-shadow: none;
        transition: .225s;
        cursor: pointer;

        &:hover {
            box-shadow: inset 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
            transition: .225s;
        }
    }
}

.profile-banner {
    width: 100%;
    height: 100%;

    .background {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .content {
        z-index: 1;

        @include media-breakpoint-down(xl) {
            .title {
                font-size: $h1-font-size;
            }

            .list {
                font-size: $h4-font-size;
            }

            .text {
                font-size: $h5-font-size;
                letter-spacing: 1px;
            }
        }

        @include media-breakpoint-up(xl) {
            .title {
                font-size: $h1-font-size * 1.125;
            }

            .list {
                font-size: $h3-font-size * .85;
            }

            .text {
                font-size: $h4-font-size * .85;
                letter-spacing: 1px;
            }
        }

        @include media-breakpoint-up(xxl) {
            .title {
                font-size: $h1-font-size * 1.175;
            }

            .list {
                font-size: $h3-font-size * .8;
            }

            .text {
                font-size: $h4-font-size * .8;
                letter-spacing: 1px;
            }
        }

        @media only screen and (min-width: 1537px) {
            .title {
                font-size: $h1-font-size * 1.25;
            }

            .list {
                font-size: $h3-font-size * .95;
            }

            .text {
                font-size: $h4-font-size * .95;
                letter-spacing: 1px;
            }
        }

    }
}