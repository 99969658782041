// Button Palette Utilities

.btn-primary {
    border: 1px solid #000 !important;
}

.btn-secondary {
    border: 1px solid #000 !important;
}

.btn-light {
    background-color: inherit;
    color: $light
}

.btn-dark-green-outline {
    border: 1px solid $black !important;
    background-color: inherit !important;
    color: $black !important;
    transition: .15s;
}

.btn-dark-green-outline:hover {
    background-color: $dark-gray !important;
    color: $white !important;
    transition: .15s;
}

.btn-dark-green-outline-white {
    border: 1px solid #2E2A2B !important;
    background-color: #2E2A2B !important;
    color: $white !important;
    transition: .225s;

    @include media-breakpoint-only(md) {
        font-size: $h6-font-size !important;
    }

    @include media-breakpoint-only(sm) {
        font-size: $h6-font-size * 0.85 !important;
    }
}

.btn-dark-green-outline-white:hover {
    background-color: $primary !important;
    border: 1px solid $black !important;
    color: $black !important;
    transition: .225s;
}

.btn-white-outline-white {
    border: 1px solid #2E2A2B !important;
    background-color: #FFFFFF !important;
    color: $black !important;
    transition: .225s;

    @include media-breakpoint-only(md) {
        font-size: $h6-font-size !important;
    }

    @include media-breakpoint-only(sm) {
        font-size: $h6-font-size * 0.85 !important;
    }
}

.btn-white-outline-white:hover {
    background-color: $primary !important;
    border: 1px solid $black !important;
    color: $black !important;
    transition: .225s;
}

.btn-dark-ligth-outline {
    border: 1px solid $dark-gray !important;
    background-color: inherit !important;
    color: $dark-gray !important;
    transition: .15s;
}

.btn-dark-ligth-outline:hover {
    background-color: $dark-gray !important;
    color: $white !important;
    transition: .15s;
}

.btn-solid-blue {
    background-color: #00B2E3;
    border: 1px solid #00B2E3;
    color: white;
    transition: .225s;
}

.btn-solid-blue:hover {
    background-color: #2E2A2B;
    border: 1px solid #2E2A2B;
    color: white;
    transition: .225s;
}

.btn-coal {
    background-color: $coal;
    border: 1px solid $coal;
    color: $white;
    transition: .225s;
}

.btn-outline-white {
    background-color: inherit;
    border: 1px solid $white !important;
    color: $white;

    &:hover {
        color: $white;
    }
}

.btn-light-coal {
    background-color: #EEE;
    border: 1px solid $light-coal;

    &:active {
        box-shadow: inset 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    }
}

.btn-laurel {
    background-color: $laurel !important;
    border: 2px solid $laurel !important;
    color: $white !important;
    box-shadow: none !important;
    transition: .225s;

    &-outline {
        background-color: $laurel !important;
        border: 2px solid $laurel !important;
        color: $white !important;
        box-shadow: none !important;
        transition: .225s;

        &:hover {
            background-color: transparent !important;
            color: $laurel !important;
            transition: .225s;
        }
    }
}

.btn-brown {
    background-color: $brown !important;
    border: 2px solid $brown !important;
    color: $white !important;
    box-shadow: none !important;
    transition: .225s;

    &-outline {
        background-color: $brown !important;
        border: 2px solid $brown !important;
        color: $white !important;
        box-shadow: none !important;
        transition: .225s;

        &:hover {
            background-color: transparent !important;
            color: $brown !important;
            transition: .225s;
        }
    }

}

.btn-beige {
    background-color: $beige !important;
    border: 2px solid $beige !important;
    color: $coal !important;
    box-shadow: none !important;
    transition: .225s;

    &-outline {
        background-color: $beige !important;
        border: 2px solid $beige !important;
        color: $coal !important;
        box-shadow: none !important;
        transition: .225s;

        &:hover {
            background-color: transparent !important;
            color: $beige !important;
            transition: .225s;
        }
    }
}

.btn-dark-green {
    background-color: $dark-green !important;
    border: 2px solid $dark-green !important;
    color: $white !important;
    box-shadow: none !important;
    transition: .225s;

    &-outline {
        background-color: $dark-green !important;
        border: 2px solid $dark-green !important;
        color: $white !important;
        box-shadow: none !important;
        transition: .225s;

        &:hover {
            background-color: transparent !important;
            color: $dark-green !important;
            transition: .225s;
        }
    }
}

.btn-dark-brown {
    background-color: $dark-brown !important;
    border: 2px solid $dark-brown !important;
    color: $white !important;
    box-shadow: none !important;
    transition: .225s;

    &-outline {
        background-color: $dark-brown !important;
        border: 2px solid $dark-brown !important;
        color: $white !important;
        box-shadow: none !important;
        transition: .225s;

        &:hover {
            background-color: transparent !important;
            color: $dark-brown !important;
            transition: .225s;
        }
    }
}

.btn-white {
    background-color: $white !important;
    border: 2px solid $white !important;
    color: $coal !important;
    box-shadow: none !important;
    transition: .225s;
}

.facebook {
    background-color: inherit;
    color: #1877F2 !important;
    border: 1px solid $coal !important;

    @include media-breakpoint-down(lg) {
        color: $white !important;
        border: 1px solid $white !important;
    }
}


.google {
    background-color: inherit;
    color: #5a5a5a !important;
    border: 1px solid $coal !important;

    @include media-breakpoint-down(lg) {
        color: $white !important;
        border: 1px solid $white !important;
    }
}

button {
    &.tzo-hover:hover {
        background-color: $primary;
        color: $coal !important;
        transition: .225s;
    }
}