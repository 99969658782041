.sponsor-landing {
    @include media-breakpoint-up(md) {
        border: 1px solid $dark;
    }

    video {
        width: 100%;
        object-position: top;
        object-fit: cover;

        @include media-breakpoint-down(md) {
            height: 200px;
        }

        @include media-breakpoint-only(md) {
            height: 325px;
        }

        @include media-breakpoint-up(lg) {
            height: 380px;
        }

        @media only screen and (min-width: 1920px) {
            height: 425px;
        }
    }

    .rac-logo-sponsor {
        width: 320px;
        @include media-breakpoint-up(lg) {
            width: 320px;
        }

        @include media-breakpoint-only(md) {
            width: 200px;
        }

        @include media-breakpoint-down(md) {
            width: 265px;
        }
    }
}